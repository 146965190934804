import { useEffect } from "react";
import { TableType } from "core/tables";
import { mainMapBoxController } from "./MainMapBox.controller";
import { MapGlContainer } from "components";
import { observer } from "mobx-react";

export const MainMapBox = observer(() => {

    useEffect(() => {
        mainMapBoxController.initTable(TableType.CountriesTable, undefined);
        mainMapBoxController.initTable(TableType.MarkerTypesTable, undefined);
        mainMapBoxController.initAreas()
    }, []);

    return <MapGlContainer/>

})
