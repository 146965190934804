import { areaService } from "services";
import { IDrawWithId } from "services/AreaService";
import { drawService } from "services/DrawService";
import { Feature, Geometry } from "geojson";
import { areaStore, drawStore } from "stores";
import { AxiosResponse } from "axios";

class AreaNoteTemplateController {

    public setAreaVisible(isVisible: boolean, id: number) {
        areaService.setAreaVisible(isVisible, id)
    }

    public setAreaToDrawData(area: IDrawWithId) {
        const {drawData} = drawStore;
        const currentDrawFeatures = drawData.features;
        const features = area.geoJson.features.filter((feature): feature is Feature<Geometry> => {
            return feature.type === "Feature" && feature.geometry.type !== 'LineString' && feature.geometry.type !== 'MultiLineString';
        })
        drawService.setDrawData(currentDrawFeatures.concat(features))
    }

    public removeAreasFromDrawData(ids: string[]) {
        const {drawData} = drawStore;
        drawService.setDrawData(drawData.features.filter(feature => {
            if (typeof feature.id === "string") return !ids.includes(feature.id)
            return []
        }))
    }

    public updateArea(area: IDrawWithId) {
        const { drawData } = drawStore;
        const areaFeatureIds = area.geoJson.features.flatMap(feature => feature.id);
        const updatedFeatures = drawData.features.filter(feature => areaFeatureIds.find(id => id === feature.id));

        if (updatedFeatures && updatedFeatures.length > 0) {

            const areaFeaturesIds: string[] = area.geoJson.features
                .flatMap(feature => feature.id)
                .filter((id): id is string => id !== undefined)

            if (area.id) {
                areaService.updateAreaGeoJsonAPIRequest(area.id, updatedFeatures).then((res: AxiosResponse<any>) => {
                    this.setAreas(res.data);
                    if (areaFeaturesIds && areaFeaturesIds.length > 0) {
                        this.removeAreasFromDrawData(areaFeaturesIds);
                    }
                });
            }
        }
    }

    public setDrawMode(drawMode: string) {
        drawService.setDrawMode(drawMode)
    }

    public setEditAreaId(id: number | undefined) {
        areaService.setEditAreaId(id)
    }

    public deleteArea(areaId: number) {
        areaService.deleteArea(areaId).then(() => {
            areaService.removeAreaFromStore(areaId)
        })
    }

    private setAreas(area: IDrawWithId) {
        const {areas} = areaStore;
        areaService.setAreas(areas.map(feature => {
            if (feature.id === area.id) return area
            return feature;
        }))
    }
}

export const areaNoteTemplateController = new AreaNoteTemplateController();
