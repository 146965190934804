import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";

interface SearchInputFormProps {
    searchTitle: string;
    handleOnQueryChange: (searchQuery: string) => void;
}

export const SearchInputForm = React.memo(({searchTitle, handleOnQueryChange}: SearchInputFormProps) => {

    return (<Formik
        initialValues={{searchQuery: ''}}
        validationSchema={Yup.object().shape({
            searchQuery: Yup.string()
        })}
        onSubmit={(values, {setSubmitting}) => {
            setSubmitting(false);
            handleOnQueryChange(values.searchQuery);
        }}
    >
        {({values, handleChange, handleSubmit}) => {
            return (<form onSubmit={handleSubmit}>
                <label htmlFor="searchQuery">{searchTitle}</label>
                <InputText
                    name="searchQuery"
                    id="searchQuery"
                    onChange={handleChange}
                    value={values.searchQuery}
                />
            </form>)
        }}
    </Formik>)
})
