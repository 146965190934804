import { Layer, Source } from "@urbica/react-map-gl";
import { SIZES } from "utils/getImageUrl";
import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { UNITS_MARKER_TYPE_ID } from "../MarkerLayers";
import { IMarker } from "core/types";
import { getCountryInfo, getMarkerZoomView } from "utils";
import { MapboxGeoJSONFeature, MapMouseEvent } from "mapbox-gl";
import { observer } from "mobx-react";

interface MarkersLayerProps {
    sourceId: string,
    iconAllowOverlap: boolean;
    data: IMarker[];
    setHoveredLayer: Dispatch<SetStateAction<MapboxGeoJSONFeature | null>>
    onMarkerClick: (marker: IMarker | null) => void;
}

export const MarkersLayer = observer(({
                                          sourceId,
                                          iconAllowOverlap,
                                          data,
                                          setHoveredLayer,
                                          onMarkerClick,
                                      }: MarkersLayerProps) => {

        const visibleZoom = useMemo(() => getMarkerZoomView('unit', undefined), []);
        const getData = useCallback((data: IMarker[]) => {
            return {
                type: 'FeatureCollection',
                features: data.map(marker => {
                    const countryCode = marker ? getCountryInfo(marker.country)?.titleNative : '00';
                    return {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [marker.coordinates.lng, marker.coordinates.lat]
                        },
                        properties: {
                            ...marker,
                            'icon-size': marker.type === UNITS_MARKER_TYPE_ID ? 0.08 : 0.335,
                            countryCode
                        }
                    };
                })
            }
        }, [])

        const handleLayerClick = useCallback((event: MapMouseEvent & { features?: MapboxGeoJSONFeature[] }) => {
            event.originalEvent.stopPropagation();
            const feature = event.features?.[0];
            if (feature) {
                onMarkerClick(feature.properties as IMarker);
            }
        }, [onMarkerClick]);

        return (
            <>
                <Source
                    id={sourceId}
                    type="geojson"
                    data={getData(data)}
                    cluster={false}
                    generateId={true}
                    minzoom={visibleZoom}
                />
                <Layer
                    id={sourceId}
                    type="symbol"
                    source={sourceId}
                    onClick={handleLayerClick}
                    onEnter={(e: any) => setHoveredLayer(e.features[0])}
                    onLeave={() => setHoveredLayer(null)}
                    layout={{
                        'icon-image': [
                            'case',
                            ['==', ['get', 'type'], UNITS_MARKER_TYPE_ID],
                            ['get', 'icon'],
                            [
                                'concat',
                                ['get', 'countryCode'],
                                '-',
                                ['get', 'icon'],
                                SIZES.SIZE_128
                            ]
                        ],
                        'icon-size': ['get', 'icon-size'],
                        'icon-allow-overlap': iconAllowOverlap
                    }}
                />
            </>
        )
    }
)
