import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { getMarkerZoomView } from "utils";

interface PointPointToastProps {
    coordinatesPoint: { lat: number, lng: number } | null;
    isPointCoordinatesEnabled: boolean;
    zoom: number;
    setCoordinatesPoint: Dispatch<SetStateAction<{ lat: number, lng: number } | null>>;
}

const isThePointSame = (prevProps: PointPointToastProps, nextProps: PointPointToastProps) => {
    return prevProps.coordinatesPoint?.lat === nextProps?.coordinatesPoint?.lat && prevProps.coordinatesPoint?.lng === nextProps.coordinatesPoint?.lng
}

export const PointToast = React.memo(({coordinatesPoint, isPointCoordinatesEnabled, zoom, setCoordinatesPoint}: PointPointToastProps) => {
    const toastRef = useRef<Toast>(null);

    const handleOnHide = useCallback(() => {
        setCoordinatesPoint(null)
    }, [setCoordinatesPoint])

    useEffect(() => {
        if (toastRef.current) {
            if (coordinatesPoint && zoom > getMarkerZoomView('info', undefined) && isPointCoordinatesEnabled) {
                const {lat, lng} = coordinatesPoint;
                const label = `${Number(lat.toFixed(4))},${Number(lng.toFixed(4))}`;
                toastRef.current.show({
                    severity: 'info',
                    summary: 'Coordinates',
                    detail: label,
                    life: 9000,
                    closable: true,
                });
            } else {
                toastRef.current.clear();
            }
        }
    }, [coordinatesPoint, zoom, isPointCoordinatesEnabled]);

    return (<Toast
        ref={toastRef}
        position="bottom-center"
        onHide={handleOnHide}
    />)
}, isThePointSame)
