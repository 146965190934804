import React, { Dispatch, SetStateAction, useMemo } from "react";
import { ActionButton } from "../";

interface TablesButtonsProps {
    mainMenuLayoutClass: string;
    mainMenuClasses: string;
    collapseOpenButtonClasses: string;
    isCreateMarker: boolean;
    isMainMenuOpened: boolean;
    actionButtons: {
        className: string,
        title: string,
        onClick: () => void,
    }[];
    setIsMainMenuOpened:Dispatch<SetStateAction<boolean>>;
}

export const TablesButtons = React.memo((
    {
        mainMenuLayoutClass,
        mainMenuClasses,
        collapseOpenButtonClasses,
        isCreateMarker,
        isMainMenuOpened,
        actionButtons,
        setIsMainMenuOpened,
    }: TablesButtonsProps) => {

    const openCollapse = useMemo(() => {
        return (
            <ActionButton
                className={collapseOpenButtonClasses}
                title={isMainMenuOpened ? '<' : '>'}
                onClick={() => setIsMainMenuOpened(!isMainMenuOpened)}
            />
        )
    }, [isMainMenuOpened, collapseOpenButtonClasses, setIsMainMenuOpened])

    return (
        <div className={mainMenuLayoutClass}>
            <div className={mainMenuClasses}>
                {actionButtons.map(button => {
                    return <ActionButton
                        key={button.title}
                        className={button.className}
                        title={button.title}
                        onClick={button.onClick}
                        disabled={isCreateMarker}
                    />
                })}
            </div>
            {openCollapse}
        </div>
    )
})
