import React, { createElement } from "react";
import { UNIT_TYPES } from "utils";
import { UnitTypes } from "core/types";

interface IUnitProps {
    icon: string | UnitTypes,
    imageClasses: string,
}

export const Unit = React.memo(({icon, imageClasses}: IUnitProps) => {
    return <>
        {UNIT_TYPES.map((unit, index) => {
            if (icon === unit.type && unit.component) return (
                <div key={unit.type + index}
                     className={imageClasses}>{createElement(unit.component)}</div>
            )
            return null
        })}
    </>
})
