import 'primeicons/primeicons.css';
import { Button } from "primereact/button";
import { useCallback, useState } from "react";
import { markersService } from "services";
import { Nullable } from "primereact/ts-helpers";
import { observer } from "mobx-react";
import { mapGlContainerController } from "components/MapGlContainer/MapGlContainer.controller";
import styles from './RefreshButton.module.scss';

interface RefreshButtonProps {
    typeIds: number[];
    eventsMarkerTypeId?: number;
    className: string;
    dates: Nullable<Date[]>;
}

export const RefreshButton = observer(({className, dates, typeIds, eventsMarkerTypeId}: RefreshButtonProps) => {

    const [isSpinning, setIsSpinning] = useState(false);

    const refreshData = useCallback(() => {
        setIsSpinning(true);
        if (dates) Promise.all([
            markersService.init(typeIds),
            mapGlContainerController.getEvents(dates, eventsMarkerTypeId)
        ]).finally(() => setIsSpinning(false));
    }, [dates, eventsMarkerTypeId, typeIds]);

    return (
        <div className={className}>
            <Button
                icon="pi pi-refresh"
                className={`p-button-rounded ${isSpinning ? styles.spin : ''}`}
                onClick={refreshData}
                aria-label="Refresh Data"
            />
        </div>
    )
})
