import React, { PropsWithChildren, useMemo } from "react";
import { observer } from "mobx-react";
import { Dropdown } from "primereact/dropdown";
import { tableStore } from "stores";
import { IStructure } from "utils/getTablesStructure";
import { ReactPortal } from "components/ReactPortal/ReactPortal";
import { ActionButton } from "components/PageLayout/ActionButton/ActionButton";
import { CloseIcon } from "assets/icons/icons";
import { IFilter, IMarkerType } from "core/types";
import styles from "./TableContainer.module.scss";
import { SearchInputForm } from "../SearchInputForm/SearchInputForm";


interface ITableContainerProps {
    title: string;
    searchTitle: string;
    structure: IStructure;
    filters: IFilter[];
    selectedValues: any[];
    onSearchQueryChange: (searchQuery: string) => void;
    onMarkerTypeChange?: (selectedMarkerType: string) => void;
    onClose: () => void;
    onHandleAddClick: () => void;
    onHandleRemoveClick: () => void;
    onHandleEditClick: () => void
}

export const TableContainer: React.FC<PropsWithChildren<ITableContainerProps>> =
    observer(({
                  title,
                  searchTitle,
                  children,
                  structure,
                  filters,
                  selectedValues,
                  onSearchQueryChange,
                  onMarkerTypeChange,
                  onClose,
                  onHandleAddClick,
                  onHandleRemoveClick,
                  onHandleEditClick,
              }) => {
        const {markerTypesData} = tableStore;

        const handleOnQueryChange = (searchQuery: string) => {
            if (onSearchQueryChange) onSearchQueryChange(searchQuery);
        }
        const handleOnMarkerTypeChange = (e: any) => {
            e.preventDefault();
            if (onMarkerTypeChange) onMarkerTypeChange(e.target.value);
        }

        const typeOptionTemplate = (option: IMarkerType) => {
            return (
                <div className="flex align-items-center">
                    <div>{option.title}</div>
                </div>
            );
        }

        const typeOptions = useMemo(() => {
            return markerTypesData.map((type) => {
                return {
                    title: type.title,
                    id: type.id
                }
            })
        }, [markerTypesData])

        return (
            <ReactPortal>
                <div className={styles.layout}>
                    <div className={styles.searchLayout}>
                        <SearchInputForm
                            searchTitle={searchTitle}
                            handleOnQueryChange={handleOnQueryChange}
                        />
                        {onMarkerTypeChange &&
                        <div className={styles.markerTypeSearch}><label htmlFor="type">Marker Type</label>
                          <Dropdown
                            inputId="type"
                            name="type"
                            value={filters.find(filter => filter.name === "markerType")?.value}
                            options={typeOptions}
                            optionLabel="title"
                            optionValue='id'
                            itemTemplate={typeOptionTemplate}
                            placeholder="Select A Type"
                            onChange={(e) => handleOnMarkerTypeChange(e)}
                          />
                        </div>}
                    </div>
                    <div className={styles.modal}>
                        <div className={styles.header}>
                            {title && (
                                <div className={styles.title}>
                                    {title}
                                </div>
                            )}
                            <div
                                className={styles.icon}
                                onClick={onClose}
                            >
                                <CloseIcon/>
                            </div>
                        </div>
                        {children}
                        <div className={styles.actionButtonsContainer}>
                            {structure.addButton && structure.addButtonTitle && <ActionButton
                              title={structure.addButtonTitle}
                              onClick={onHandleAddClick}
                              className={styles.button}
                            />}
                            {structure.editButton && structure.editButtonTitle && <ActionButton
                              title={structure.editButtonTitle}
                              onClick={onHandleEditClick}
                              className={styles.button}
                              disabled={selectedValues.length !== 1}
                            />}
                            <ActionButton
                                title={selectedValues.length > 1 ? structure.removeManyButtonTitle : structure.removeButtonTitle}
                                onClick={onHandleRemoveClick}
                                className={styles.button}
                                disabled={selectedValues.length === 0}
                            />
                        </div>
                    </div>
                </div>
            </ReactPortal>
        )
    })
