import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Card } from "primereact/card";
import { IMarker } from "core/types";
import { getCountryInfo } from "utils";
import { Events } from "components/Marker/RenderMarker";
import styles from './EventTemplate.module.scss';
import moment from "moment";

interface EventTemplateProps {
    marker: IMarker;
    onCLick: (marker: IMarker) => void;
    setSideBarVisible: Dispatch<SetStateAction<boolean>> | undefined;
}

export const EventTemplate = ({marker, onCLick, setSideBarVisible}: EventTemplateProps) => {

    const country = useMemo(() => {
        return getCountryInfo(marker.country)
    }, [marker])

    const created = useMemo(() => moment(marker.created).format('DD.MM.YYYY, HH:mm'), [marker.created])

    const handleOnCardClick = useCallback(() => {
        onCLick(marker)
        if (setSideBarVisible) setSideBarVisible(false)
    }, [marker, onCLick, setSideBarVisible])

    return <div
        className={styles.cardLayout}
        onClick={handleOnCardClick}
    >
        <Card
            subTitle={`Created: ${created}`}
            pt={{
                body: {className: styles.body}
            }}
        >
            <div className={styles.cardContentLayout}>
                <Events
                    id={marker.id}
                    icon={marker.icon}
                    titleNative={country?.titleNative ? country?.titleNative : '00'}
                    imageClasses={styles.image}
                />
                <div className={styles.text}>{marker.title}</div>
            </div>
        </Card>
    </div>
}
