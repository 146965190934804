import React, { useCallback, useEffect, useMemo, useState } from "react";
import cn from "clsx";
import { observer } from "mobx-react";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { Column, ColumnBodyOptions } from "primereact/column";
import { mapStore, modalStore, tableStore } from "stores";
import { tableService } from "services";
import { IFilter, IMarker } from "core/types";
import { TableType } from "core/tables";
import { getCountryInfo, getMarkerTypeInfo } from "utils";
import { getModalTypeByTableType, IStructure } from "utils/getTablesStructure";
import { TableContainer } from "../";
import styles from "./Table.module.scss"
import { mapActionClickType } from "components/Modals/CreateMarkerModal/CreateMarkerModal";
import { actionTypes } from "core/modals";

interface ITableProps {
    structure: IStructure;
    onClose?: (() => void) | undefined;
}

const INITIAL_FILTER_STATE: IFilter[] = [
    {
        name: 'markerType',
        value: '',
    }
]

export const Table: React.FC<ITableProps> = observer(({structure, onClose}) => {
        const {meta} = tableStore;
        const [selectedValues, setSelectedValues] = useState<any[]>([])
        const [data, setData] = useState<any[]>([]);
        const [first, setFirst] = useState<number>(0)
        const [loading, setLoading] = useState<boolean>(false)
        const [filters, setFilters] = useState<IFilter[]>(INITIAL_FILTER_STATE)

        const modalType = useMemo(() => getModalTypeByTableType(structure.tableType), [structure.tableType])

        const rowItemStyles = useMemo(() => {
            return structure.tableType === TableType.MarkersTable ? cn([styles.tableHeaderCell, styles.pointer]) : styles.tableHeaderCell
        }, [structure.tableType])

        const tableData = useMemo(() => {
            if (data && data.length > 0) return data.map((entity) => entity)
            return []
        }, [data])

        const onTableCloseHandler = useCallback(() => {
            tableStore.close();
            if (onClose) onClose();
        }, [onClose]);

        const onHandleRemoveClick = useCallback(() => {
            let ids: any[] = selectedValues.map(value => value.id);
            const params = {take: meta.take, page: meta.page}
            if (ids && ids.length > 0) tableService.removeTableRow(structure.tableType, ids).then(() => {
                tableService.initTable(structure.tableType, params).then(() => {
                    setData(tableStore.getData(structure.tableType))
                })
            })
        }, [structure.tableType, meta?.page, meta?.take, selectedValues])

        const handleOnCheckedChanged = (e: any) => {
            setSelectedValues(e.value);
        }

        const onHandleAddClick = useCallback(() => {
            if (modalType) {
                modalStore.open({
                    type: modalType,
                    actionType: undefined,
                })
            }
        }, [modalType])

        const onHandleEditClick = useCallback(() => {
            if (modalType && selectedValues.length === 1) {
                modalStore.open({
                    type: modalType,
                    actionType: mapActionClickType.EDIT_MARKER as actionTypes,
                    markerData: data.filter((entity: IMarker) => entity.id === selectedValues[0].id)[0],
                    onClose: () => tableService.initTable(structure.tableType, {
                        take: meta.take,
                        page: meta.page
                    }).then(() => {
                        setData(tableStore.getData(structure.tableType))
                    })
                })
            }
        }, [modalType, data, selectedValues, structure.tableType, meta?.page, meta?.take])

        const onSearchQueryChange = useCallback((searchQuery: string) => {
            setLoading(true);
            tableService.initTable(structure.tableType, {
                page: 0,
                take: meta.take,
                search: searchQuery ? searchQuery : undefined
            }).then(() => {
                setData(tableStore.getData(structure.tableType))
                setLoading(false);
            })

        }, [meta?.take, structure.tableType])

        const onMarkerTypeChange = useCallback((selectedMarkerType: string) => {
            const _filters = filters.map((filter) => {
                if (filter.name === 'markerType') filter.value = selectedMarkerType;
                return filter
            })
            setFilters(_filters)
        }, [filters])

        const handleOnTitleClicked = useCallback((marker: IMarker) => {
            tableStore.close();
            mapStore.onMapMove({
                latitude: marker.coordinates.lat,
                longitude: marker.coordinates.lng,
                zoom: 11,
            })
            if (onClose) onClose();
        }, [onClose])

        useEffect(() => {
            tableService.setMetaToStore({...tableStore.meta, take: structure.pagination.rows, page: 0})
            tableService.initTable(structure.tableType, {take: structure.pagination.rows, page: 0}).then(() => {
                setData(tableStore.getData(structure.tableType))
            })
        }, [structure.tableType, structure.pagination.rows])

        const cellTemplate = useCallback((data: any, options: ColumnBodyOptions) => {

            if (structure.tableType === TableType.MarkersTable) {
                if (options.field === 'title') {
                    return <div key={data[options.field]}
                                className={rowItemStyles}
                                onClick={() => handleOnTitleClicked(data as IMarker)}
                    >
                        {data[options.field]}
                    </div>
                }

                if (options.field === 'markerType') {
                    return <div key={data[options.field]}
                                className={styles.tableHeaderCell}
                    >
                        {getMarkerTypeInfo(data.type)?.title}
                    </div>
                }

                if (options.field === 'country') {
                    return <div key={data[options.field]}
                                className={styles.tableHeaderCell}
                    >
                        {getCountryInfo(data.country)?.title}
                    </div>
                }
                return <div key={options.field}
                            className={styles.tableHeaderCell}
                >
                    {data[options.field]}
                </div>

            }

            if (options.field === 'path') {
                return <div key={data}
                            className={rowItemStyles}
                >
                    {data}
                </div>
            }

            return <div key={data[options.field]}
                        className={rowItemStyles}
                        onClick={() => handleOnTitleClicked(data as IMarker)}
            >
                {data[options.field]}
            </div>


        }, [handleOnTitleClicked, rowItemStyles, structure.tableType])

        const onPage = useCallback((event: DataTableStateEvent) => {
            setLoading(true);
            tableService.initTable(structure.tableType, {page: event.page ? event.page : 0, take: event.rows}).then(() => {
                setData(tableStore.getData(structure.tableType))
                setFirst(event.first);
                setLoading(false);
            })
        }, [structure.tableType])

        const dataTable = useMemo(() => {
            return (
                <DataTable
                    value={tableData}
                    paginator={structure.pagination.paginator}
                    rows={meta?.take ? meta?.take : 5}
                    rowsPerPageOptions={structure.pagination.rowsPerPageOptions}
                    scrollable
                    scrollHeight="600px"
                    dataKey="id"
                    selectionMode="checkbox"
                    selection={selectedValues}
                    lazy
                    loading={loading}
                    totalRecords={meta?.itemCount}
                    first={first}
                    onSelectionChange={handleOnCheckedChanged}
                    onPage={onPage}
                >
                    {structure.headers.map((header) => {
                        if (header.name === 'checkBox') {
                            return <Column
                                key={header.name}
                                selectionMode="multiple"
                                headerStyle={{width: '3rem'}}
                            />
                        }
                        return <Column
                            key={header.name}
                            field={header.entityField}
                            header={header.name}
                            body={cellTemplate}
                        />
                    })}
                </DataTable>
            )
        }, [
            cellTemplate,
            meta?.itemCount,
            meta?.take,
            selectedValues,
            structure.headers,
            structure.pagination.paginator,
            structure.pagination.rowsPerPageOptions,
            tableData,
            loading,
            first,
            onPage
        ])

        return (
            <TableContainer
                title={structure.tableTitle}
                searchTitle={structure.searchTitle}
                structure={structure}
                selectedValues={selectedValues}
                filters={filters}
                onClose={onTableCloseHandler}
                onSearchQueryChange={onSearchQueryChange}
                onMarkerTypeChange={structure.tableType === TableType.MarkersTable ? onMarkerTypeChange : undefined}
                onHandleAddClick={onHandleAddClick}
                onHandleRemoveClick={onHandleRemoveClick}
                onHandleEditClick={onHandleEditClick}
            >
                {dataTable}
            </TableContainer>)
    }
)
