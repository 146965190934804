import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo } from 'react';
import {
    Marker as MarkerMapBox
} from '@urbica/react-map-gl';
import { LngLat } from "mapbox-gl";
import { mapStore, markersStore } from "stores";
import { markersService } from "services";
import { IMarker } from "core/types";
import { getColorByCountry, getCountryInfo, getMarkerTypeInfo, getMarkerZoomView, getRights } from "utils";
import { MarkerLayout } from "./MarkerLayout/MarkerLayout";
import { RenderMarker } from "./RenderMarker/RenderMarker";
import { DEVICE_TYPES, DeviceType } from "hooks/useDeviceType";
import RightContext from "contexts/AppRightsProvider";
import { reaction } from "mobx";

interface IMarkerProps {
    marker: IMarker;
    isMarkerVisible: boolean;
    onClick?: (args: IMarker) => void;
    isCreateMarker: boolean;
    isMove: boolean;
    setIsMove: Dispatch<SetStateAction<boolean>>;
    deviceType: DeviceType;
    onMarkerClick: (marker: IMarker | null) => void;
}

const EVENTS_MARKER_TYPE_ID = 33;

export const Marker = React.memo(({
                                      marker,
                                      isMove,
                                      isCreateMarker,
                                      isMarkerVisible,
                                      setIsMove,
                                      deviceType,
                                      onMarkerClick,
                                  }: IMarkerProps) => {

    const {rights} = useContext(RightContext);
    const markerType = useMemo(() => {
        return getMarkerTypeInfo(marker.type)
    }, [marker])

    const handleOnMarkerClick = useCallback((event: MouseEvent) => {
        event.stopPropagation();
        if (!isMove && !isCreateMarker && onMarkerClick) onMarkerClick(marker);
    }, [isMove, isCreateMarker, marker, onMarkerClick])

    const handleOnMarkerDragEnd = (lngLat: LngLat) => {
        const {effects, coordinates, ...restFields} = marker
        const payload = {
            ...restFields,
            coordinates: lngLat,
        }
        if (getRights(rights, 'editMarker')) {
            markersService.updateMarkerAPIRequest(payload).then((res) => {
                if (res) {
                    markersStore.updateMarkersInStore(res.data)
                }
                onMarkerClick(null)
                setIsMove(false)
            })
        } else {
            markersStore.updateMarkersInStore({
                ...payload,
            })
            onMarkerClick(null)
            setIsMove(false)
        }

    }

    const country = useMemo(() => {
        return getCountryInfo(marker.country)
    }, [marker])

    const color = useMemo(() => {
        if (country) return getColorByCountry(country.titleNative)
        return getColorByCountry("default")
    }, [country])

    const getIsDraggable: () => boolean = useCallback(() => {
        if (deviceType !== DEVICE_TYPES.DESKTOP) return false
        return markerType?.id !== EVENTS_MARKER_TYPE_ID;
    }, [deviceType, markerType?.id])

    useEffect(() => {
        const disposer = reaction(
            () => mapStore.zoom,
            (zoom) => {
                return zoom > getMarkerZoomView(markerType?.title, marker.icon)
            }
        );
        return () => disposer(); // Cleanup the reaction on unmount
    })

    return (
        <>
            {isMarkerVisible && <MarkerMapBox
              key={marker.title}
              longitude={marker.coordinates.lng}
              latitude={marker.coordinates.lat}
              anchor="bottom"
              onDragStart={() => setIsMove(true)}
              onDragEnd={(lngLat) => handleOnMarkerDragEnd(lngLat)}
              onClick={handleOnMarkerClick}
              draggable={getIsDraggable()}
            >
                {markerType && country && <MarkerLayout
                  title={marker.title}
                  isMove={isMove}
                >
                  <RenderMarker
                    id={marker.id}
                    icon={marker.icon}
                    markerTypeTitle={markerType?.title}
                    titleNative={country?.titleNative}
                    color={color}
                    isMove={isMove}
                  />
                </MarkerLayout>}
            </MarkerMapBox>}
        </>
    );
})
