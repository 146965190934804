export enum TableType {
    CountriesTable = 'CountriesTable',
    MarkerTypesTable = 'MarkerTypesTable',
    MarkersTable = 'MarkersTable',
}

interface BaseTableProps {
    onClose?: () => void;
    onOpen?: () => void;
}

interface CountriesTableProps extends BaseTableProps {
    type: TableType.CountriesTable;
}

interface MarkerTypesTableProps extends BaseTableProps {
    type: TableType.MarkerTypesTable;
}

interface MarkersTableProps extends BaseTableProps {
    type: TableType.MarkersTable;
}

export type TableProps = CountriesTableProps | MarkerTypesTableProps | MarkersTableProps
