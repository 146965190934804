import React from "react";
import { UnitTypes } from "core/types";
import { getImageUrl } from "utils";
import { SIZES } from "utils/getImageUrl";

export interface IEventsProps {
    id?: number
    icon: string | UnitTypes,
    imageClasses?: string,
    titleNative: string,
    size?: string,
}

export const Events = React.memo(({icon, titleNative, imageClasses, size = SIZES.SIZE_64}: IEventsProps) => {
    const imageUrl = getImageUrl(titleNative, icon, size)
    return <img className={imageClasses} src={imageUrl.url} alt={imageUrl.type}/>
})
