import { markersStore, notificationStore } from 'stores';
import { TableType } from "core/tables";
import { IMarker, IMarkerPayload } from "core/types";
import { apiService, authService, mapService, notificationService } from "./";
import { ApiConfig } from "./ApiService";
import { AxiosResponse } from "axios";
import { getCountryInfo, getImageUrl } from "utils";

export class MarkersService {

    async init(typeIds: number[]) {
        await markersService.getMarkers({typeIds: typeIds}).catch(() => authService.setInvalidState())
    }

    public async createMarkerAPIRequest(payload: IMarkerPayload) {
        return await apiService.axiosPostRequest(ApiConfig.MARKER, payload).then((res) => {
            const countryInfo = getCountryInfo(res.data.country)
            const countryCode = countryInfo ? countryInfo.titleNative : '00';
            const image = getImageUrl(countryCode, res.data.icon)
            mapService.loadImage(image.url, image.type, false);
            markersStore.addMarkerToStore(res.data)
            notificationStore.push(notificationService.create({
                data: res.data,
                dataType: TableType.MarkersTable,
                type: 'success',
                actionType: 'create'
            }))
        }).catch((e) => {
            if (e.response.status === 401) authService.refreshAuthState()
            if (e.response.status === 403) authService.setInvalidState()
        });
    }

    public async updateMarkerAPIRequest(payload: IMarkerPayload): Promise<AxiosResponse<IMarker | any> | void> {
        const {id, ...newPayload} = payload
        if (id) return await apiService.axiosPatchRequest(ApiConfig.updateMarker(id), {...newPayload}).catch((e) => {
            if (e.response.status === 401) authService.refreshAuthState()
            if (e.response.status === 403) authService.setInvalidState()
        })
        return undefined
    }

    public async getMarkers({typeIds}: { typeIds: number[] }) {
        return await apiService.axiosGetRequest(ApiConfig.MARKER, undefined, {typeIds: typeIds.join(',')}).then((res) => {
            const {data} = res;
            markersStore.setMarkers(data.data)
        }).catch((e) => {
            if (e.response.status === 401) authService.refreshAuthState()
            if (e.response.status === 403) authService.setInvalidState()
        });
    }

    public async getEvents({typeIds, dates}: {
        typeIds: number[],
        dates: { createdFrom: string, createdTo: string } | undefined
    }) {
        return await apiService.axiosGetRequest(ApiConfig.MARKER, undefined,
            {
                typeIds: typeIds.join(','),
                createdFrom: dates ? dates.createdFrom : undefined,
                createdTo: dates ? dates.createdTo : undefined,
            }
        ).then((res) => {
            const {data} = res;
            markersStore.setEvents(data.data)
        }).catch((e) => {
            if (e.response.status === 401) authService.refreshAuthState()
            if (e.response.status === 403) authService.setInvalidState()
        });
    }

}

export const markersService = new MarkersService();
