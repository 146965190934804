import { ModalType } from "core/modals";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { IAreaPayload, ICountry, ICountryPayload, IMarkerType, IMarkerTypePayload } from "core/types";
import { areaActionTypes } from "components/Modals/CreateModal/CreateModal";

export interface IField {
    name: string,
    id: string,
    title: string,
    component: any
}

export interface CountryInitialValues {
    title: string,
    titleNative: string,
    code: string,
    desc: string
}

export interface MarkerTypeInitialValues {
    title: string,
    icon: string,
    desc: string
}

export interface AreaInitialValues {
    title: string,
    desc: string,
    isRemovable: boolean | undefined,
    isCollection: boolean | undefined,
}

export type InitialValues = CountryInitialValues | MarkerTypeInitialValues | AreaInitialValues

export interface IModalStructure {
    modalType: ModalType,
    fields: IField[],
    getCreateButtonTitle: (value: string | undefined) => string,
    getInitialValues: (props: IAreaPayload | ICountryPayload | IMarkerTypePayload | undefined) => InitialValues
}

export const MODAL_STRUCTURES: IModalStructure[] = [
    {
        modalType: ModalType.CreateCountry,
        fields: [
            {
                name: "title",
                id: "title",
                title: 'Title',
                component: InputText
            }, {
                name: "titleNative",
                id: "titleNative",
                title: 'Native Title',
                component: InputText
            }, {
                name: "code",
                id: "code",
                title: 'Code',
                component: InputText
            }, {
                name: "desc",
                id: "desc",
                title: 'Description',
                component: InputTextarea
            }
        ],
        getCreateButtonTitle: ()=> 'Create Country',
        getInitialValues: (country) => {
            let _country = country as ICountry
            if (_country) return {
                title: _country.title,
                titleNative: _country.titleNative,
                code: _country.code,
                desc: _country.desc
            }
            return {title: '', titleNative: '', code: '', desc: ''}
        }
    }, {
        modalType: ModalType.CreateMarkerTypes,
        fields: [
            {
                name: "title",
                id: "title",
                title: 'Title',
                component: InputText
            }, {
                name: "icon",
                id: "icon",
                title: 'Icon',
                component: InputText
            }, {
                name: "desc",
                id: "desc",
                title: 'Description',
                component: InputTextarea
            }
        ],
        getCreateButtonTitle: ()=> 'Create Marker Type',
        getInitialValues: (markerType) => {
            let _markerType = markerType as IMarkerType
            if (_markerType) return {
                title: _markerType.title,
                icon: _markerType.icon,
                desc: _markerType.desc
            }
            return {title: '', icon: '', desc: ''}
        }
    },{
        modalType: ModalType.AreaInfo,
        fields: [
            {
                name: "title",
                id: "title",
                title: 'Title',
                component: InputText
            },{
                name: "desc",
                id: "desc",
                title: 'Description',
                component: InputTextarea
            }
        ],
        getCreateButtonTitle: (actionType) => {
            if (actionType === areaActionTypes.UPDATE_AREA) return 'Update Area Information'
            return 'Create Area'
        },
        getInitialValues: (area) => {
            const isRemovable = area && "geoJson" in area ? area?.geoJson?.features.every(feature => feature?.properties?.isRemovable) : false
            const isCollection = area && "geoJson" in area ? area?.geoJson?.features.every(feature => feature?.properties?.isCollection) : false

            let _area = area
            if (_area) return {
                title: _area.title,
                desc: _area.desc,
                isRemovable: isRemovable,
                isCollection: isCollection,
            }
            return {title: '', desc: '', isRemovable: false, isCollection: false}
        }
    },
]

export const getCreateModalsStructure = (modalType: ModalType): IModalStructure => {
    return MODAL_STRUCTURES.find(modal => modal.modalType === modalType) as IModalStructure
}
