import { Rights } from "core/types";

export const getRights = (role: Rights, field: string): boolean => {
    if (role === 'admin') return true
    if (role === 'user' || role === 'creator' || role === 'pro') return (RIGHTS as any)[role][field]
    return false
}

const RIGHTS = {
    user: {
        coordinatesButton: false,
        tablesButton: false,
        changeMap: false,
        editMarker: false,
        hideAds: true,
        hideCommercials: false,
        areaController: false,
    },
    creator: {
        coordinatesButton: true,
        tablesButton: true,
        changeMap: false,
        editMarker: true,
        hideAds: true,
        hideCommercials: true,
        areaController: false,
    },
    pro: {
        coordinatesButton: false,
        tablesButton: false,
        changeMap: false,
        editMarker: false,
        hideAds: true,
        hideCommercials: true,
        areaController: false,
    }
}
