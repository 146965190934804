import { makeObservable, observable, runInAction } from "mobx";
import { IMe } from "core/types";

export class MeStore {
    me: IMe | null = null;

    constructor() {
        makeObservable(this, {
            me: observable,
        });
        this.setMe = this.setMe.bind(this)

    }

    setMe(me: IMe | null) {
        runInAction(() => this.me = me)
    }

    getMe() {
        return this.me
    }
}

export const meStore = new MeStore();
