import { IColor } from "core/types";

export const getColorByCountry = (titleNative: string = 'default'): IColor => {

    if (titleNative === "am") {
        return {
            main: '#D90012',
            stopColor: '#D90012',
            secondaryStopColor: '#D90012',
            secondary: '#010101',
            content: '#F2A800',

        }
    }

    if (titleNative === "us") {
        return {
            main: '#002868',
            stopColor: '#a20627',
            secondaryStopColor: '#BF0A30',
            secondary: '#010101',
            content: '#FFFFFF',

        }
    }

    if (titleNative === "az") {
        return {
            main: '#0092BC',
            stopColor: '#0092BC',
            secondaryStopColor: '#0092BC',
            secondary: '#010101',
            content: '#00AF66',

        }
    }

    if (titleNative === "ua") {
        return {
            main: '#1c437a',
            stopColor: '#2257a5',
            secondaryStopColor: '#1b6bd9',
            secondary: '#010101',
            content: '#ffcd00',

        }
    }

    if (titleNative === "fr") {
        return {
            main: '#1c437a',
            stopColor: '#2257a5',
            secondaryStopColor: '#1b6bd9',
            secondary: '#010101',
            content: '#ffffff',

        }
    }

    if (titleNative === "by") {
        return {
            main: '#17641b',
            stopColor: '#0c871a',
            secondaryStopColor: '#55a934',
            secondary: '#010101',
            content: '#b51c1c',

        }
    }
    if (titleNative === "default") {
        return {
            main: "#964b00",
            stopColor: "#964b00",
            secondaryStopColor: "#964b00",
            secondary: '#010101',
            content: '#ffffff',
        }
    }
    if (titleNative === "ru") {
        return {
            main: '#801615',
            stopColor: '#b51c1c',
            secondaryStopColor: '#d65431',
            secondary: '#010101',
            content: '#ffffff',
        }
    }
    if (titleNative === "il") {
        return {
            main: '#ffffff',
            stopColor: '#ffffff',
            secondaryStopColor: '#ffffff',
            secondary: '#0035B9',
            content: '#0035B9',
        }
    }

    if (titleNative === "ps") {
        return {
            main: '#000000',
            stopColor: '#ded7d7',
            secondaryStopColor: '#E4312b',
            secondary: '#000000',
            content: '#149954',
        }
    }

    if (titleNative === "ir") {
        return {
            main: '#DA0000',
            stopColor: '#3ea153',
            secondaryStopColor: '#239F40',
            secondary: '#DA0000',
            content: '#ffffff',
        }
    }

    if (titleNative === "nato") {
        return {
            main: '#004586',
            stopColor: '#000000',
            secondaryStopColor: '#000000',
            secondary: '#000000',
            content: '#0B89C6',
        }
    }

    return {
        main: "#964b00",
        stopColor: "#964b00",
        secondaryStopColor: "#964b00",
        secondary: '#010101',
        content: '#ffffff',
    }
}
