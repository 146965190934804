import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikErrors } from 'formik';
import { Navigate, useNavigate } from 'react-router-dom';
import { authService } from 'services';
import { routes } from 'core/navigation';
import { useAppAuth } from 'hooks/useAppAuth';
import styles from './SignUp.module.scss';

export const SignUp = observer(() => {
  const { authState } = useAppAuth();
  const navigate = useNavigate();


  if (authState.isAuthenticated) {
    return <Navigate to={routes.main.path} />;
  }

  const redirectSignIn = () => {
      return navigate(routes.login.path)
  }

    const redirectToEnterCode = (email: string) => {
        return navigate(routes.enterCode.path, {
            state: {
                email: email,
            }
        })
    }

  return (
    <>
      <div className={styles.title}>Military Summary</div>
      <div className={styles.authContainer}>
        <Formik
          initialValues={{ email: '', password: '' }}
          validate={(values) => {
            const errors: FormikErrors<any> = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            authService.signUp({ ...values, lang: 'en', roles: ['user'] }).then((res) => {
                console.log('Line 48 res.data: ', res.data)
                redirectToEnterCode(values.email)
                setSubmitting(false);
                return;
            }).catch(error => {
                setSubmitting(false);
                if (error.response.data.message === 'User exists') redirectToEnterCode(values.email)
            });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form className={styles.authForm} onSubmit={handleSubmit}>
                <div className={styles.formTitle}>Create a new account</div>
                <label className={styles.authInput} htmlFor="email">Enter your email</label>
                <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                />
                {errors.email && touched.email && errors.email}
                <label className={styles.authInput} htmlFor="password">Enter your password</label>
                <input
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                />
                {errors.password && touched.password && errors.password}
                  <button className={styles.button} type="submit" disabled={isSubmitting}>
                    Sign Up
                  </button>
                <div className={styles.signUp} onClick={redirectSignIn}>Already have an account?</div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
});
