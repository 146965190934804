import { useMemo } from "react";
import { observer } from 'mobx-react';
import { useAppStore } from "hooks/useAppStore";
import { Table } from "./";
import { getTablesStructure } from "utils/getTablesStructure";

export const Tables = observer(() => {
    const {tableStore} = useAppStore();
    const {tableProps} = tableStore;
    const structure = useMemo(() => {
        if (tableProps?.type) return getTablesStructure(tableProps.type)
        return undefined
    }, [tableProps])

    if (!structure) {
        return <></>;
    }

    if (structure)
        return <Table
            structure={structure}
            onClose={tableProps?.onClose}
            {...tableProps}
        />
    return <></>
});
