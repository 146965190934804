import React from "react";

interface ActionButtonProps {
    className?: string;
    imageClassName?: string;
    title: string;
    imgSrc?: string | null;
    onClick: () => void;
    disabled?: boolean;
    altText?: string; // Добавлено новое свойство для альтернативного текста
}

export const ActionButton = React.memo(({
                                            className = "",
                                            imageClassName = "",
                                            title,
                                            imgSrc = null,
                                            onClick,
                                            disabled = false,
                                            altText = "",
                                        }: ActionButtonProps) => {
    return (
        <button className={className} onClick={onClick} disabled={disabled}>
            {imgSrc ? (
                <img className={imageClassName} src={imgSrc} alt={altText || title} />
            ) : (
                title
            )}
        </button>
    );
});
