import React from 'react';
import { observer } from 'mobx-react';
import { Formik } from 'formik';
import { Navigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { authStore } from "stores";
import { authService, meService, patreonService } from 'services';
import { routes } from 'core/navigation';
import { useAppAuth } from 'hooks/useAppAuth';
import styles from './Signin.module.scss';

export interface FormValues {
    email: string;
    password: string;
}

export interface FormErrors {
    email?: string;
    password?: string;
}

export const SignIn = observer(() => {
    const {authState} = useAppAuth();
    const {isLoading} = authStore;

    if (authState.isAuthenticated) {
        return <Navigate to={routes.main.path}/>;
    }

    const authLink = patreonService.getAuthLink()

    const validate = (values: FormValues): FormErrors => {
        const errors: FormErrors = {};
        if (!values.email) {
            errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        return errors;
    };

    return (
        <>
            <div className={styles.title}>Military Summary</div>
            <div className={styles.authContainer}>
                {isLoading ? <ProgressSpinner/> :
                    <>
                        <Formik
                            initialValues={{email: '', password: ''}}
                            validate={validate}
                            onSubmit={(values, {setSubmitting, resetForm}) => {
                                authService.logIn({...values}).then((res) => {
                                    authService.setAuthState(res.data);
                                    meService.getMeRequest(res.data.accessToken)
                                    setSubmitting(false);
                                    resetForm()
                                    return;
                                }).catch(() => {
                                    setSubmitting(true)
                                    resetForm()
                                    return;
                                });
                            }}
                        >
                            {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                                <form className={styles.authForm} onSubmit={handleSubmit}>
                                    <div className={styles.formTitle}>Log Into Military Summary</div>
                                    <div className={styles.inputLayout}>
                                        <label className={styles.authInput} htmlFor="email">Enter your email</label>
                                        <InputText
                                            type="email"
                                            name="email"
                                            id="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        {errors.email && touched.email && errors.email}
                                        <label className={styles.authInput} htmlFor="password">Enter your
                                            password</label>
                                        <InputText
                                            type="password"
                                            name="password"
                                            id="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                        />
                                        {errors.password && touched.password && errors.password}
                                    </div>
                                    <Button label="Submit" type="submit" disabled={isSubmitting} severity="success"
                                            raised/>
                                </form>
                            )}
                        </Formik>
                        <Button label="Log in with Patreon" onClick={() => {
                            window.location.href = authLink
                        }}/>
                    </>}
            </div>
            <div className={styles.container}>
                <div className={styles.instruction}>
                    <h2>Short Instruction:</h2>
                    <p>You need to have a 5$ subscription to get access to the map - Patreon:</p>
                    <a href="https://www.patreon.com/militarySummary?fan_landing=true" target="_blank"
                       rel="noopener noreferrer">https://www.patreon.com/militarySummary?fan_landing=true</a>
                    <p>If you have a subscription, then you need to follow the next steps:</p>
                    <ol>
                        <li>Step 1: You need to visit dev.militarysummary.com</li>
                        <li>Step 2: There you should press Login with Patreon</li>
                        <li>Step 3: This will redirect you to the Patreon permission Page</li>
                        <li>Step 4: On this page, you need to press the Allow button</li>
                        <li>Step 5: You will be redirected to the map after step 4</li>
                    </ol>
                    <p><strong>IMPORTANT NOTE:</strong> You should verify your email in the Patreon system.</p>
                </div>
            </div>
        </>
    );
});
