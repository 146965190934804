import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikErrors } from 'formik';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { authService } from 'services';
import { routes } from 'core/navigation';
import { useAppAuth } from 'hooks/useAppAuth';
import styles from './EnterCode.module.scss';

interface LocationState {
    email: string
}

export const EnterCode = observer(() => {
  const { authState } = useAppAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { email } = state as LocationState;

  if (authState.isAuthenticated) {
    return <Navigate to={routes.main.path} />;
  }

  const redirectSignIn = () => {
      return navigate(routes.login.path)
  }

  return (
    <>
      <div className={styles.title}>Military Summary</div>
      <div className={styles.authContainer}>
        <Formik
          initialValues={{ email: email, code: '' }}
          validate={(values) => {
            const errors: FormikErrors<any> = {};
            if (!values.code) {
              errors.code = 'Required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            authService.activateCode({ ...values }).then(() => {
                setSubmitting(false);
                return;
            });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form className={styles.authForm} onSubmit={handleSubmit}>
                <div className={styles.formTitle}>Create a new account</div>
                <label className={styles.authInput} htmlFor="code">Enter your Code</label>
                <input
                    type="text"
                    name="code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.code}
                />
                {errors.code && touched.code && errors.code}
                  <button className={styles.button} type="submit" disabled={isSubmitting}>
                    Verify Code
                  </button>
                <div className={styles.signUp} onClick={redirectSignIn}>Already have an account?</div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
});
