import React, { memo, useState, useCallback } from "react";
import styles from "../Marker.module.scss";

// Определение типов пропсов
interface MarkerLayoutProps {
    title: string;
    isMove: boolean;
    children: React.ReactNode;
}

export const MarkerLayout: React.FC<MarkerLayoutProps> = memo(({ children, title, isMove }) => {
    const [isDisplayName, setIsDisplayName] = useState<boolean>(false);

    const handleMouseEnter = useCallback(() => setIsDisplayName(true), []);
    const handleMouseLeave = useCallback(() => setIsDisplayName(false), []);

    return (
        <>
            {isDisplayName && !isMove && (
                <div className={styles.markerTitle}>{title}</div>
            )}
            <div
                className={styles.markerWrapper}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </div>
        </>
    );
});
