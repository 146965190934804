import React, { useCallback } from "react";
import { Marker } from '@urbica/react-map-gl';
import { observer } from "mobx-react";
import { mapStore } from "stores";
import { mapActionClickType } from "../Modals/CreateMarkerModal/CreateMarkerModal";
import { actionTypes } from "core/modals";
import styles from './CoordinatesMarker.module.scss'
import { coordinatesMarkerController } from "./CoordinatesMarker.controller";

export const CoordinatesMarker: React.FC = observer(() => {
    const { markerCoordinates} = mapStore;

    const onClick = useCallback((event: MouseEvent) => {
        event.stopPropagation();
        mapStore.onMapClick({
                onClose: () => coordinatesMarkerController.setMarkerCoordinates(undefined),
                actionType: mapActionClickType.CREATE_MARKER as actionTypes
            }
        )
    }, []);

    if (!markerCoordinates) return null;

    return (
        <Marker
            latitude={markerCoordinates[0]}
            longitude={markerCoordinates[1]}
            anchor="bottom"
            onClick={onClick}
        >
            <div className={styles.marker}/>
        </Marker>
    );
});




