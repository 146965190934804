import { areaStore } from 'stores';
import { Feature } from 'geojson';
import { ApiConfig, apiService } from './ApiService';

export interface IDraw {
    title: string,
    desc: string,
    geoJson: {
        id?: string,
        type: string,
        features: Feature[]
    }
}

export interface IDrawWithId extends IDraw {
    id?: number,
}

export interface IAreaBase {
    id?: number
    title?: string,
    desc?: string,
    isRemovable?: boolean,
    isCollection?: boolean,
}

export interface IAreaPayload extends IAreaBase {
    geoJson: {
        id?: string,
        type?: string,
        features?: Feature[]
    }
}

export class AreaService {

    initAreas() {
        this.getAreas();
    }

    setAreas(areas: IDrawWithId[]) {
        areaStore.setAreas(areas);
    }

    addAreaToStore(area: IDrawWithId) {
        areaStore.addAreaToStore(area);
    }

    removeAreaFromStore(areaId: number) {
        areaStore.removeAreaFromStore(areaId);
    }

    setEditAreaId(id: number | undefined) {
        areaStore.setEditAreaId(id);
    }

    async deleteArea(id: number) {
        return await apiService.axiosDeleteRequest(ApiConfig.deleteArea(id))
    }

    updateAreaInStore(area: IDrawWithId) {
        areaStore.updateAreaInStore(area);
    }

    setAreaVisible(isVisible: boolean, id: number) {
        areaStore.setAreaVisible(isVisible, id)
    }

    public getAreas() {
        Promise.all([
            apiService.axiosGetRequest(ApiConfig.AREA)
        ])
            .then(res => res.map(i => i.data))
            .then(res => res.map((i, index) => {
                if (index === 0) {
                    return i.data;
                }
                return [i];
            }))
            .then(data => data[0])
            .then((areas: IDraw[]) => {
                if (areas?.length) this.setAreas(areas)

            });
    }

    public setTerritories() {
        areaStore.setTerritories()
    }


    public async updateAreaGeoJsonAPIRequest(collectionId: number, features: Feature[]) {
        const payload: IAreaPayload = {
            id: collectionId,
            geoJson: {
                features: features
            }
        };
        return await apiService.axiosPatchRequest(ApiConfig.updateArea(collectionId), payload);
    }

    public async updateAreaAPIRequest(area: IAreaBase, features: Feature[]) {
        if (area.id) {
            const payload: IAreaPayload = {
                id: area.id,
                title: area.title,
                desc: area.desc,
                geoJson: {
                    type: "FeatureCollection",
                    features: features,
                }
            };
            return await apiService.axiosPatchRequest(ApiConfig.updateArea(area.id), payload);
        }
        return
    }

    public async createAreaAPIRequest(area: IAreaBase) {
        if (area.title && area.desc) {
            const payload: IDraw = {
                title: area.title,
                desc: area.desc,
                geoJson: {
                    type: "FeatureCollection",
                    features: []
                }
            }
            return await apiService.axiosPostRequest(ApiConfig.createArea(), payload);
        }
        return
    }
}


export const areaService = new AreaService();
