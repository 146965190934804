import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { colorsService } from "services";
import { DEFAULT_DRAW_PROPS } from "stores/DrawStore";
import { IDrawProps } from "core/draws";
import { ColorButton } from "./ColorButton/ColorButton";
import { colorsButtonControllerController } from "./ColorsButton.controller";
import styles from './ColorsButtons.module.scss'

const COLORS = ['#ff0000', '#fdcb00', '#000', '#156104', '#0a58a9', '#fff']

interface ColorsButtonsProps {
    handleOnModeChangeClicked: (mode: string, drawProperties: IDrawProps) => void;
}

export const ColorsButtons = observer(({handleOnModeChangeClicked}: ColorsButtonsProps) => {
    const [isChangeColorOpen, setIsChangeColorOpen] = useState<boolean>(false)
    const {color} = colorsService;
    const handleOnClick = useCallback(() => {
        setIsChangeColorOpen(!isChangeColorOpen)
    }, [isChangeColorOpen])

    const onColorButtonClick = useCallback(() => {
        colorsButtonControllerController.setDrawProps(DEFAULT_DRAW_PROPS)
        handleOnModeChangeClicked('simple_select', DEFAULT_DRAW_PROPS);
    }, [handleOnModeChangeClicked])

    return (<div className={styles.colorsButtonsLayout}>
        {isChangeColorOpen && COLORS.map((color, index) => <ColorButton
            key={index}
            color={color}
            setIsChangeColorOpen={setIsChangeColorOpen}
            resetDrawState={onColorButtonClick}
        />)}
        {!isChangeColorOpen && <ColorButton
          color={color}
          setIsChangeColorOpen={setIsChangeColorOpen}
          onClick={handleOnClick}
        />}
    </div>)
})
