import { makeObservable, runInAction, observable } from 'mobx';

export type NotificationTypes = 'default' | 'warning' | 'error' | 'success';

export type ActionTypes = 'create' | 'delete' | 'update';

export interface INotification {
    id: string;
    content: string;
    type: NotificationTypes;
}

interface IRemove {
    payload: { id: string; };
}

export class NotificationStore {
    public notifications: INotification[] = [];


    constructor() {
        makeObservable(this, {
            notifications: observable,
        });

        this.push = this.push.bind(this);
        this.remove = this.remove.bind(this);
        this.clear = this.clear.bind(this);
    }

    push(notification: INotification) {
        runInAction(() => {
            this.notifications.push(notification)
            if (this.notifications.length > 5) {
                const length = this.notifications.length;
                this.notifications = this.notifications.slice(length > 5 ? length - 5 : 0);
            }
        })
    }

    remove({ payload }: IRemove) {
        runInAction(() => {
            this.notifications = this.notifications.filter((n) => n.id !== payload.id);
        })
    }

    clear() {
        runInAction(() => {
            this.notifications = [];
        })
    }
}

export const notificationStore = new NotificationStore();
