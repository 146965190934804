import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppAuth } from 'hooks/useAppAuth';
import { routes } from 'core/navigation';
import { observer } from "mobx-react";

export const PrivateRoute = observer(() => {
    const {authState} = useAppAuth();
    const location = useLocation();

    if (!authState.isAuthenticated) {
        return <Navigate to={routes.login.path} replace state={{from: location}}/>;
    }
    return <Outlet/>;
})
