import {  IFeatures } from "stores/AreaStore";
import { areaService } from "services";
import { drawService } from "services/DrawService";
import { IDrawProps } from "core/draws";
import { Feature } from "geojson";


export class DrawComponentController {

    public initAreas() {
        areaService.initAreas()
    }

    public setDrawData(features: IFeatures) {
        drawService.setDrawData(features)
    }

    public setDrawProps(drawProps: IDrawProps) {
        drawService.setDrawProps(drawProps)
    }

    public setDrawMode(drawMode: string) {
        drawService.setDrawMode(drawMode)
    }

    public setAreaToSave(feature: Feature) {
        drawService.setAreaToSave(feature)
    }

    public clearAreaToSave() {
        drawService.clearAreaToSave()
    }

    public removeAreaToSave(id: string) {
        drawService.removeAreaToSave(id)
    }
}

export const drawComponentController = new DrawComponentController();
