import { useEffect, useState } from 'react';

export const DEVICE_TYPES = {
    MOBILE: 'mobile' as DeviceType,
    TABLET: 'tablet' as DeviceType,
    DESKTOP: 'desktop' as DeviceType,
};

export type DeviceType = 'mobile' | 'tablet' | 'desktop';

const useDeviceType = (): DeviceType => {
    const [deviceType, setDeviceType] = useState<DeviceType>(DEVICE_TYPES.DESKTOP);

    useEffect(() => {
        const mobileQuery = window.matchMedia('(max-width: 600px)');
        const tabletQuery = window.matchMedia('(max-width: 1024px)');

        const handler = () => {
            if (mobileQuery.matches) {
                setDeviceType(DEVICE_TYPES.MOBILE);
            } else if (tabletQuery.matches) {
                setDeviceType(DEVICE_TYPES.TABLET);
            } else {
                setDeviceType(DEVICE_TYPES.DESKTOP);
            }
        };

        // Инициализация начального состояния
        handler();

        // Подписка на изменения
        mobileQuery.addEventListener('change', handler);
        tabletQuery.addEventListener('change', handler);

        return () => {
            mobileQuery.removeEventListener('change', handler);
            tabletQuery.removeEventListener('change', handler);
        };
    }, []);

    return deviceType;
};

export default useDeviceType;
