import React, { Dispatch, SetStateAction } from "react";
import { getMarkerTypeInfo, getMarkerZoomView } from "utils";
import { Marker } from "components";
import { mapStore } from "stores";
import { IMarker } from "core/types";
import { DeviceType } from "hooks/useDeviceType";
import { observer } from "mobx-react";

interface MarkerContainerProps {
    markersData: IMarker[];
    isMove: boolean;
    isCreateMarker: boolean;
    deviceType: DeviceType;
    setIsMove: Dispatch<SetStateAction<boolean>>;
    onMarkerClick: (marker: IMarker | null) => void;
}

export const MarkerContainer = observer(({
                                             markersData,
                                             isMove,
                                             isCreateMarker,
                                             deviceType,
                                             setIsMove,
                                             onMarkerClick,
                                         }: MarkerContainerProps) => {
    return <>{markersData.map((marker) => {
            const {zoom} = mapStore;

            const markerType = getMarkerTypeInfo(marker.type)

            const isMarkerVisible = markerType && zoom > getMarkerZoomView(markerType?.title, marker.icon)

            if (isMarkerVisible) return (
                <Marker
                    key={marker.id}
                    marker={marker}
                    isMove={isMove}
                    isCreateMarker={isCreateMarker}
                    setIsMove={setIsMove}
                    deviceType={deviceType}
                    onMarkerClick={onMarkerClick}
                    isMarkerVisible={isMarkerVisible}
                />
            )
            return null
        }
    )
    }</>
})
