import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Formik } from "formik";
import * as Yup from "yup";
import { TableType } from "core/tables";
import { ICoordinates, IEventType, IForceType, IMarker, IMarkerType, IUnitType } from "core/types";
import { actionTypes, markerActionTypes } from "core/modals";
import { modalStore, tableStore, mapStore, markersStore, notificationStore } from "stores";
import {
    mapService,
    markersService,
    notificationService,
} from "services";
import { getMarkerTypeInfo, UNIT_TYPES, EVENTS_TYPES, FORCES_TYPES, getCountryInfo, getImageUrl } from "utils";
import { SIZES } from "utils/getImageUrl";
import { ModalContainer } from "../ModalContainer/ModalContainer";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import styles from "./CreateMarkerModal.module.scss";
import { Events } from "../../Marker/RenderMarker";

export const mapActionClickType = {
    CREATE_MARKER: 'CREATE_MARKER' as markerActionTypes,
    MOVE_MARKER: 'MOVE_MARKER' as markerActionTypes,
    COPY_MARKER: 'COPY_MARKER' as markerActionTypes,
    EDIT_MARKER: 'EDIT_MARKER' as markerActionTypes,
}

interface CreateMarkerProps {
    onClose?: (() => void);
    markerData?: IMarker;
    actionType?: actionTypes
}

export const CreateMarkerModal: React.FC<CreateMarkerProps> = observer(({
                                                                            onClose,
                                                                            markerData,
                                                                            actionType
                                                                        }) => {
        const {countriesData, markerTypesData} = tableStore;
        const {markerCoordinates} = mapStore;
        const [markerTypeId, setMarkerTypeId] = useState<number | undefined>(markerData?.type)

        const markerType: IMarkerType | undefined = useMemo(() => {
            if (markerTypeId) return getMarkerTypeInfo(markerTypeId)
            return undefined
        }, [markerTypeId])

        const onModalCloseHandler = useCallback(() => {
            modalStore.close();
            if (onClose) onClose();
        }, [onClose]);

        const typeOptionTemplate = (option: IMarkerType) => {
            return (
                <div className="flex align-items-center">
                    <div>{option.title}</div>
                </div>
            );
        }

        const iconOptionTemplate = (option: IUnitType | IEventType | IForceType) => {
            return <>{
                option.component ? <div className={styles.selectControlLayout}>
                    <Events
                        icon={option.value}
                        titleNative={'00'}
                        size={SIZES.SIZE_32}
                    />
                    <div className={styles.selectControlName}>{option.name}</div>
                </div> : <div className={styles.selectControlName}>{option.name}</div>
            }</>
        }

        const iconValueTemplate = (option: IUnitType | IEventType | IForceType, props: DropdownProps) => {
            if (option) {
                return <>{
                    option.component ? <div className={styles.selectControlLayout}>
                        <Events
                            icon={option.value}
                            titleNative={'00'}
                            size={SIZES.SIZE_32}
                        />
                        <div className={styles.selectControlName}>{option.name}</div>
                    </div> : <div className={styles.selectControlName}>{option.name}</div>
                }</>
            }
            return <span>{props.placeholder}</span>;
        }

        const typeOptions = useMemo(() => {
            return markerTypesData.map((type) => {
                return {
                    title: type.title,
                    id: type.id
                }
            })
        }, [markerTypesData])

        const iconOptions = useMemo(() => {
            if (markerType?.title === "unit") {
                return UNIT_TYPES.map((type) => {
                    return {
                        title: type.type,
                        id: markerTypesData.find((markerType) => markerType?.title === "unit")?.id,
                        value: type.value,
                        name: type.name,
                        component: type.component,
                    }
                })
            }
            if (markerType?.title === "Events") {
                return EVENTS_TYPES.map((type) => {
                    return {
                        title: type.type,
                        id: markerTypesData.find((markerType) => markerType?.title === "Events")?.id,
                        value: type.value,
                        name: type.name,
                        component: type.component,
                    }
                })
            }
            if (markerType?.title === "forces") {
                return FORCES_TYPES.map((type) => {
                    return {
                        title: type.type,
                        id: markerTypesData.find((markerType) => markerType?.title === "forces")?.id,
                        value: type.value,
                        name: type.name,
                        component: type.component,
                    }
                })
            }
            return undefined

        }, [markerType?.title, markerTypesData])

        const countryOptions = useMemo(() => {
            return countriesData.map((type) => {
                return {
                    title: type.title,
                    id: type.id
                }
            })
        }, [countriesData])

        const getCoordinatesString = (coordinates: ICoordinates) => {
            return `${coordinates.lat},${coordinates.lng}`
        }

        const getInitialCoordinates = useCallback(() => {
            if (markerCoordinates && actionType === mapActionClickType.CREATE_MARKER) return `${markerCoordinates[0]},${markerCoordinates[1]}`
            if (markerData?.coordinates
                && (actionType === mapActionClickType.EDIT_MARKER
                    || actionType === mapActionClickType.COPY_MARKER)) return getCoordinatesString(markerData?.coordinates)
            return getCoordinatesString(mapStore.mapProps.lngLat)
        }, [markerCoordinates, markerData?.coordinates, actionType])

        const getModalButtonText = useCallback(() => {
            if (actionType === mapActionClickType.COPY_MARKER) return 'Finish Copy'
            if (markerData && actionType === mapActionClickType.EDIT_MARKER) return 'Update'
            return 'Create'
        }, [markerData, actionType])

        return (
            <ModalContainer title={markerTypesData ? 'Edit Marker' : 'Create Marker'} onClose={onModalCloseHandler}>
                <Formik
                    initialValues={{
                        coordinates: getInitialCoordinates(),
                        type: markerData?.type || 0,
                        country: markerData?.country || 0,
                        title: markerData?.title || '',
                        desc: markerData?.desc || '',
                        icon: markerData?.icon || '',
                        image: markerData?.image || '',
                        videoUrl: markerData?.videoUrl || '',
                    }}
                    validationSchema={Yup.object().shape({
                        type: Yup.number().moreThan(0, 'You need to choose a Type').required('Type is required!'),
                        country: Yup.number().moreThan(0, 'You need to choose a Country').required('Country is required!'),
                        title: Yup.string().required('Title is required!'),
                        icon: Yup.string().required('Icon is required!'),
                        coordinates: Yup.string().matches(/^-?\d{1,2}\.\d+,\s*-?\d{1,3}\.\d+$/, "Only Coordinates Allowed")
                    })}
                    onSubmit={async (values, {setSubmitting}) => {
                        setSubmitting(false);
                        modalStore.close()
                        const {coordinates, ...restValues} = values;
                        if (markerData && actionType === mapActionClickType.EDIT_MARKER) {
                            markersStore.removeMarkerFromStore([markerData.id])
                            markersService.updateMarkerAPIRequest({
                                id: markerData.id,
                                coordinates: {
                                    lat: parseFloat(coordinates.replace(/\s+$/g, "").split(',')[0]),
                                    lng: parseFloat(coordinates.replace(/\s+$/g, "").split(',')[1])
                                },
                                ...restValues,
                            }).then(res => {
                                if (res) {
                                    const countryInfo = getCountryInfo(res.data.country)
                                    const countryCode = countryInfo ? countryInfo.titleNative : '00';
                                    const image = getImageUrl(countryCode, res.data.icon)
                                    mapService.loadImage(image.url, image.type, false)
                                    markersStore.addMarkerToStore({...res.data})
                                    notificationStore.push(notificationService.create({
                                        data: res.data,
                                        dataType: TableType.MarkersTable,
                                        type: 'success',
                                        actionType: 'update'
                                    }));
                                }
                                if (onClose) onClose()
                            });
                        }
                        if (actionType === mapActionClickType.CREATE_MARKER || actionType === mapActionClickType.COPY_MARKER) {
                            markersService.createMarkerAPIRequest({
                                ...restValues,
                                coordinates: {
                                    lat: parseFloat(coordinates.replace(/\s+$/g, "").split(',')[0]),
                                    lng: parseFloat(coordinates.replace(/\s+$/g, "").split(',')[1])
                                },
                            }).then(() => {
                                if (onClose) onClose()
                            });
                        }

                    }}
                >
                    {({values, errors, handleChange, handleBlur, handleSubmit, isSubmitting}) => {
                        return (
                            <form className={styles.layout} onSubmit={handleSubmit}>
                                <div className={styles.formLayout}>
                                    <div className={styles.form}>
                                        <label htmlFor="type">Marker Type</label>
                                        <Dropdown
                                            inputId="type"
                                            name="type"
                                            value={values.type}
                                            options={typeOptions}
                                            optionLabel="title"
                                            optionValue='id'
                                            itemTemplate={typeOptionTemplate}
                                            placeholder="Select A Type"
                                            onChange={(e) => {
                                                setMarkerTypeId(parseInt(e.target.value))
                                                handleChange(e)
                                            }}
                                        />
                                        {errors.type &&
                                          <div className={styles.errors}>
                                              {errors.type}
                                          </div>}
                                        <label htmlFor="country">Country</label>
                                        <Dropdown
                                            inputId="country"
                                            name="country"
                                            value={values.country}
                                            options={countryOptions}
                                            optionLabel="title"
                                            optionValue='id'
                                            itemTemplate={typeOptionTemplate}
                                            placeholder="Select A Country"
                                            onChange={(e) => handleChange(e)}
                                        />
                                        {errors.country &&
                                          <div className={styles.errors}>
                                              {errors.country}
                                          </div>}
                                        <label htmlFor="title">Title</label>
                                        <InputText
                                            name="title"
                                            id="title"
                                            onChange={handleChange}
                                            value={values.title}
                                        />
                                        {errors.title &&
                                          <div className={styles.errors}>
                                              {errors.title}
                                          </div>}
                                        <label htmlFor="icon">Icon</label>
                                        {markerType?.title !== "unit" && markerType?.title !== "Events" && markerType?.title !== 'forces' ?
                                            <InputText
                                                name="icon"
                                                id="icon"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.icon}
                                            /> :
                                            <Dropdown
                                                inputId="icon"
                                                name="icon"
                                                value={values.icon}
                                                options={iconOptions}
                                                optionLabel="title"
                                                optionValue="value"
                                                itemTemplate={iconOptionTemplate}
                                                valueTemplate={iconValueTemplate}
                                                placeholder="Select An Icon"
                                                onChange={(e) => {
                                                    handleChange(e)
                                                }}
                                            />
                                        }
                                        {errors.icon &&
                                          <div className={styles.errors}>
                                              {errors.icon}
                                          </div>}
                                        <label htmlFor="image">Image url</label>
                                        <InputText
                                            name="image"
                                            id="image"
                                            onChange={handleChange}
                                            value={values.image}
                                        />
                                        <label htmlFor="videoUrl">Video url</label>
                                        <InputText
                                            name="videoUrl"
                                            id="videoUrl"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.videoUrl}
                                        />
                                    </div>
                                    <div className={styles.form}>
                                        <label htmlFor="desc">Description</label>
                                        <InputTextarea
                                            id="desc"
                                            name="desc"
                                            rows={10}
                                            cols={40}
                                            onChange={handleChange}
                                            value={values.desc}
                                        />

                                        <label htmlFor="coordinates">Coordinates</label>
                                        <InputText
                                            name="coordinates"
                                            id="coordinates"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.coordinates}
                                        />
                                        {errors.coordinates &&
                                          <div className={styles.errors}>
                                              {errors.coordinates}
                                          </div>}
                                    </div>
                                </div>
                                <button className={styles.button} type="submit" disabled={isSubmitting}>
                                    {getModalButtonText()}
                                </button>
                            </form>
                        )
                    }}
                </Formik>
            </ModalContainer>
        )
    }
)
