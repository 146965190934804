import { areaService } from "services";

class TerritoryLayersController {

    public setTerritories() {
        areaService.setTerritories()
    }
}

export const territoryLayersController = new TerritoryLayersController();
