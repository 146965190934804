import React, { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import PaintMode from "mapbox-gl-draw-paint-mode";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";
import Draw from "@urbica/react-map-gl-draw";
import { IDrawData } from "stores/DrawStore";
import { drawStore } from "stores";
import { DEFAULT_DRAW_PROPS } from "stores/DrawStore";
import { colorsService } from "services";
import { createDrawStyles } from "utils";
import { IDistance } from "core/types";
import { lineString } from "@turf/helpers";
import turfLength from "@turf/length";
import useDeviceType, { DEVICE_TYPES } from "hooks/useDeviceType";
import { drawComponentController } from "./DrawComponent.controller";

interface IDrawComponentProps {
    distances: IDistance[],
    setDistances: Dispatch<SetStateAction<IDistance[]>>;
}

export const DrawComponent = observer(({
                                           distances,
                                           setDistances
                                       }: IDrawComponentProps) => {

    const deviceType = useDeviceType();
    const {color} = colorsService;
    const {drawProps, drawMode, drawData} = drawStore;
    const drawRef: MutableRefObject<any | null> = useRef(null);

    const drawStyles = useMemo(() => computed(() => createDrawStyles(color)), [color]).get();

    const handleOnDrawSelectionChange = (e: any) => {
        if (e?.features?.length === 0) return drawComponentController.clearAreaToSave()
        if (e?.features[0]?.geometry.type === 'Polygon'
            && !e?.features[0]?.properties?.id) return drawComponentController.setAreaToSave(e.features[0])
    }

    const handleOnDrawDelete = (e: any) => {
        drawComponentController.removeAreaToSave(e.features[0].id)
    }

    const handleOnDrawCreate = useCallback((e: any) => {
        const draw = drawRef.current.getDraw()
        const feature = draw.get(e.features[0].id)
        if (feature) {
            draw.setFeatureProperty(feature.id, 'color', color)
            if (drawProps.arrowMode) draw.setFeatureProperty(feature.id, 'arrowMode', true)
            if (drawProps.distanceMode) {
                if (feature.geometry?.type === "LineString") {
                    const line = lineString(feature.geometry.coordinates);
                    const milesOfLine = turfLength(line, {units: 'miles'});
                    const kilometersOfLine = turfLength(line, {units: 'kilometers'});
                    setDistances(distances.concat({
                        coordinates: e.features.find((feature: any) => feature?.type === "Feature").geometry.coordinates,
                        miles: milesOfLine,
                        kilometers: kilometersOfLine,
                        id: `${milesOfLine}${kilometersOfLine}`
                    }))
                }
            }
        }

    }, [color, distances, drawProps.distanceMode, drawProps.arrowMode, setDistances])

    useEffect(() => {
        drawComponentController.initAreas()
    }, []);

    useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (event.key === "Delete") {
                if (drawRef.current && drawRef.current.getDraw()) drawRef.current.getDraw().trash()
            }
        }

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    if (deviceType === DEVICE_TYPES.DESKTOP) {
        return <Draw
            ref={drawRef}
            displayControlsDefault={false}
            modes={(modes: any) => {
                modes.static = StaticMode;
                modes.draw_paint_mode = PaintMode;
                return modes
            }}
            mode={drawMode}
            uncombineFeaturesControl={false}
            combineFeaturesControl={false}
            polygonControl={false}
            lineStringControl={false}
            pointControl={false}
            trashControl={false}
            position='bottom-left'
            data={{
                type: drawData.type,
                features: drawData.features.filter(feature => feature)
            }}
            styles={drawStyles}
            userProperties={true}
            onChange={(drawData: IDrawData) => {
                drawComponentController.setDrawData(drawData.features)
                drawComponentController.setDrawProps(DEFAULT_DRAW_PROPS)
            }}
            onDrawCreate={handleOnDrawCreate}
            onDrawModeChange={({mode}: { mode: string }) => {
                if (mode !== 'direct_select') drawComponentController.setDrawMode(mode)
            }}
            onDrawSelectionChange={handleOnDrawSelectionChange}
            onDrawDelete={handleOnDrawDelete}
        />
    }
    return <></>

})
