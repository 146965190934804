import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from './components/route';
import { routes } from './core/navigation';
import { EnterCode, MainMapBox, SignIn, SignUp } from "./pages";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";

export default function App() {
    return (
        <Routes>
            <Route path={routes.login.path} element={<SignIn/>}/>
            <Route path={routes.signUp.path} element={<SignUp/>}/>
            <Route path={routes.enterCode.path} element={<EnterCode/>}/>
            <Route path="/" element={<PrivateRoute/>}>
                <Route path={routes.main.path} element={<MainMapBox/>}/>
            </Route>
            <Route path="*" element={<Navigate to={routes.main.path} replace/>}/>
        </Routes>
    );
}
