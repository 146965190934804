import { getNotificationByDataType } from "utils/getNotificationByDataType";
import { getActionByType } from "utils/getActionByType";
import { ActionTypes, NotificationTypes } from "stores/NotificationStore";
import { TableType } from "core/tables";

interface CreateNotificationProps {
    data?: any,
    dataType: TableType,
    type: NotificationTypes,
    actionType: ActionTypes
}

export class NotificationService {

    create({data = undefined, dataType, type, actionType}: CreateNotificationProps) {
        if (data) return {
            id: data.id,
            content: `${getNotificationByDataType(dataType)} ${data.title} has been successfully ${getActionByType(actionType)}`,
            type: type,
        }
        return {
            id: Math.random().toString(),
            content: `${getNotificationByDataType(dataType)} has been successfully ${getActionByType(actionType)}`,
            type: type,
        }
    }
}

export const notificationService = new NotificationService();
