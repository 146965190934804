import React from "react";
import { ReactPortal } from "components/ReactPortal/ReactPortal";
import { CloseIcon } from "assets/icons/icons";
import styles from "./ModalContainer.module.scss";

interface ModalContainerProps {
    title?: string;
    children: React.ReactNode;
    onClose: () => void;
}


export const ModalContainer: React.FC<ModalContainerProps> = React.memo(({title, children, onClose}) => {
    return (
        <ReactPortal>
            <div className={styles.layout}>
                <div className={styles.modal}>
                    <div className={styles.header}>
                        {title && <div className={styles.title}>{title}</div>}
                        <button
                            className={styles.icon}
                            onClick={onClose}
                            aria-label="Close modal"
                        >
                            <CloseIcon/>
                        </button>
                    </div>
                    {children}
                </div>
            </div>
        </ReactPortal>
    )
})
