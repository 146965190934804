import React, { Dispatch, PropsWithChildren, SetStateAction, useContext, useMemo, useState } from 'react';
import { observer } from "mobx-react";
import cn from 'clsx';
import { Nullable } from "primereact/ts-helpers";
import RightContext from "contexts/AppRightsProvider";
import { tableService } from 'services';
import { mapStore, tableStore } from "stores";
import { TableType } from 'core/tables';
import { IMarker } from "core/types";
import { IDrawProps } from "core/draws";
import { getRights } from "utils";
import {
    Notifications,
    CalendarComponent,
    CoordinatesButton,
    ControlButtons,
    TablesButtons,
    SideBarLayout,
    RefreshButton,
    PointToast, AreasSideBarLayout
} from './index';
import useDeviceType from "hooks/useDeviceType";
import styles from './PageLayout.module.scss';

interface IPageLayoutProps {
    coordinatesPoint: { lat: number, lng: number } | null;
    setCoordinatesPoint: Dispatch<SetStateAction<{ lat: number, lng: number } | null>>;
    typeIds: number[];
    eventsMarkerTypeId: number | undefined;
    isCreateMarker: boolean;
    dates: Nullable<Date[]>;
    setDates: Dispatch<SetStateAction<Nullable<Date[]>>>
    onMarkerClick: (marker: IMarker) => void;
    handleOnModeChangeClicked: (mode: string, drawProperties: IDrawProps | undefined) => void;
    createMarker: () => void;
}

export const PageLayout: React.FC<PropsWithChildren<IPageLayoutProps>> = observer(({
                                                                                       children,
                                                                                       coordinatesPoint,
                                                                                       setCoordinatesPoint,
                                                                                       typeIds,
                                                                                       eventsMarkerTypeId,
                                                                                       isCreateMarker,
                                                                                       dates,
                                                                                       setDates,
                                                                                       onMarkerClick,
                                                                                       handleOnModeChangeClicked,
                                                                                       createMarker,
                                                                                   }) => {
    const deviceType = useDeviceType();
    const [isPointCoordinatesEnabled, setIsPointCoordinatesEnabled] = useState<boolean>(false);
    const {zoom} = mapStore;
    const {rights} = useContext(RightContext);
    const {tableProps} = tableStore;
    const [isMainMenuOpened, setIsMainMenuOpened] = useState<boolean>(false)

    const openTable = (tableType: TableType) => {
        tableService.openTable(tableType)
    }

    const countryTableButtonClasses = cn([
        styles.button,
        tableProps?.type === TableType.CountriesTable ? styles.selected : ''
    ]);
    const collapseOpenButtonClasses = cn([
        styles.button,
        styles.collapseOpen,
        tableProps?.type === TableType.CountriesTable ? styles.selected : ''
    ]);
    const markerTypesTableButtonClasses = cn([
        styles.button,
        tableProps?.type === TableType.MarkerTypesTable ? styles.selected : ''
    ]);
    const markersTableButtonClasses = cn([
        styles.button,
        tableProps?.type === TableType.MarkersTable ? styles.selected : ''
    ])
    const mainMenuClasses = cn([
        styles.mainMenu,
        isMainMenuOpened ? styles.mainMenu_opened : styles.mainMenu_closed
    ])

    const actionButtons: {
        className: string,
        title: string,
        onClick: () => void,
    }[] = useMemo(() => {
        return [
            {
                className: countryTableButtonClasses,
                title: 'Countries',
                onClick: () => openTable(TableType.CountriesTable)
            }, {
                className: markerTypesTableButtonClasses,
                title: 'Types',
                onClick: () => openTable(TableType.MarkerTypesTable)
            }, {
                className: markersTableButtonClasses,
                title: 'Markers',
                onClick: () => openTable(TableType.MarkersTable)
            },
        ]
    }, [countryTableButtonClasses, markerTypesTableButtonClasses, markersTableButtonClasses])

    return (
        <div>
            {children}
            {getRights(rights, 'tablesButton') && <TablesButtons
              mainMenuLayoutClass={styles.mainMenuLayout}
              mainMenuClasses={mainMenuClasses}
              collapseOpenButtonClasses={collapseOpenButtonClasses}
              isCreateMarker={isCreateMarker}
              actionButtons={actionButtons}
              isMainMenuOpened={isMainMenuOpened}
              setIsMainMenuOpened={setIsMainMenuOpened}/>}
            <ControlButtons
                deviceType={deviceType}
                createMarker={createMarker}
                handleOnModeChangeClicked={handleOnModeChangeClicked}
                isCreateMarker={isCreateMarker}
                layoutClassName={styles.buttonsLayout}
                isPointCoordinatesEnabled={isPointCoordinatesEnabled}
                setIsPointCoordinatesEnabled={setIsPointCoordinatesEnabled}
            />
            <CalendarComponent
                dates={dates}
                setDates={setDates}
                className={styles.calendar}
            />
            {getRights(rights, 'coordinatesButton') && <CoordinatesButton
              className={styles.coordinatesInput}
            />}
            <Notifications/>
            <SideBarLayout
                accountInfoClassName={styles.mainImage}
                onMarkerClick={onMarkerClick}
                deviceType={deviceType}
            />
            <AreasSideBarLayout
                accountInfoClassName={styles.mainImage}
                deviceType={deviceType}
            />
            <RefreshButton
                className={styles.refreshButton}
                dates={dates}
                typeIds={typeIds}
                eventsMarkerTypeId={eventsMarkerTypeId}
            />
            <PointToast
                coordinatesPoint={coordinatesPoint}
                isPointCoordinatesEnabled={isPointCoordinatesEnabled}
                zoom={Number(zoom.toFixed(1))}
                setCoordinatesPoint={setCoordinatesPoint}
            />
        </div>
    );
});
