import { makeObservable, observable, runInAction } from 'mobx';
import { AuthState } from 'contexts/AppAuthProvider';

export class AuthStore {
    authState: AuthState = {
        accessToken: undefined,
        isAuthenticated: false,
    };
    isLoading: boolean = false;

    constructor() {
        makeObservable(this, {
            authState: observable,
            isLoading: observable,
        });

        this.setLoadingState = this.setLoadingState.bind(this)
    }

    public setAuthState(accessToken: string | undefined, isAuthenticated: boolean) {
        runInAction(() => {
            this.authState.accessToken = accessToken;
            this.authState.isAuthenticated = isAuthenticated;
        })
    }

    public getAuthState() {
        return this.authState;
    }

    public setLoadingState(loadingState: boolean) {
        runInAction(() => {
            this.isLoading = loadingState;
        })
    }
}

export const authStore = new AuthStore();
