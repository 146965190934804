import { IEventType } from "core/types";

import {
    Events,
} from "components/Marker/RenderMarker/Events/Events";

export const EVENTS_TYPES: IEventType[] = [
    {
        type: 'explosion',
        value: 'explosion',
        name: 'Explosion',
        component: Events,
    }, {

        type: 'attention',
        value: 'attention',
        name: 'Attention',
        component: Events,
    }, {

        type: 'airDefense',
        value: 'airDefense',
        name: 'Air Defense',
        component: Events,
    }, {

        type: 'shelling',
        value: 'shelling',
        name: 'Shelling',
        component: Events,
    }, {
        type: 'fight',
        value: 'fight',
        name: 'Fight',
        component: Events,
    }, {
        type: 'flag',
        value: 'flag',
        name: 'Flag',
        component: Events,
    },
    {
        type: 'casualties',
        value: 'casualties',
        name: 'Casualties',
        component: Events,
    }, {
        type: 'stop',
        value: 'stop',
        name: 'Stop',
        component: Events
    }, {
        type: 'electricity',
        value: 'electricity',
        name: 'Electricity',
        component: Events,
    }, {
        type: 'camp',
        value: 'camp',
        name: 'Camp',
        component: Events
    }, {
        type: 'fire',
        value: 'fire',
        name: 'Fire',
        component: Events,
    }, {
        type: 'manPads',
        value: 'manPads',
        name: 'ManPads',
        component: Events
    }, {
        type: 'train',
        value: 'train',
        name: 'Train',
        component: Events
    }, {
        type: 'microphone',
        value: 'microphone',
        name: 'Microphone',
        component: Events
    }, {
        type: 'plane',
        value: 'plane',
        name: 'Plane',
        component: Events,
    }, {
        type: 'mines',
        value: 'mines',
        name: 'Mines',
        component: Events,
    }, {
        type: 'missile',
        value: 'missile',
        name: 'Missile',
        component: Events,
    }, {
        type: 'chemical',
        value: 'chemical',
        name: 'Chemical',
        component: Events
    }, {
        type: 'arrest',
        value: 'arrest',
        name: 'Arrest',
        component: Events,
    }, {
        type: 'drone',
        value: 'drone',
        name: 'Drone',
        component: Events,
    }, {
        type: 'car',
        value: 'car',
        name: 'Car',
        component: Events
    }, {
        type: 'bus',
        value: 'bus',
        name: 'Bus',
        component: Events
    }, {
        type: 'ship',
        value: 'ship',
        name: 'Ship',
        component: Events,
    }, {
        type: 'terrorist',
        value: 'terrorist',
        name: 'Terrorist',
        component: Events
    }, {
        type: 'health',
        value: 'health',
        name: 'Health',
        component: Events
    }, {
        type: 'signal',
        value: 'signal',
        name: 'Signal',
        component: Events
    }, {
        type: 'picture',
        value: 'picture',
        name: 'Picture',
        component: Events,
    }, {
        type: 'authority',
        value: 'authority',
        name: 'Authority',
        component: Events
    },
]
