import { ModalStore, modalStore } from './ModalStore';
import { TableStore, tableStore } from './TableStore';

export interface AppStore {
  modalStore: ModalStore;
  tableStore: TableStore;
}

export const appStore = {
  modalStore,
  tableStore
} as AppStore;
