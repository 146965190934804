import React from "react";
import { Rights } from "core/types";
import { getRights } from "utils";
import { CloseIcon } from "assets/icons/icons";
import styles from "../Popup.module.scss";

interface IPopupLayoutProps {
    children?: React.ReactNode,
    className: string,
    rights: Rights
    handleOnCloseClick: () => void,
    handleOnPlayClick?: () => void,
    handleOnEditClick: () => void,
    handleOnCopyClick?: () => void,
    handleOnMoveClick?: () => void,
    handleOnCopyToClick?: () => void,
}

export const PopupLayout = React.memo(({
                                           children,
                                           className,
                                           rights,
                                           handleOnCloseClick,
                                           handleOnPlayClick,
                                           handleOnEditClick,
                                           handleOnCopyClick,
                                           handleOnMoveClick,
                                           handleOnCopyToClick
                                       }: IPopupLayoutProps) => {
    return (<div className={className}>
        <button className={styles.closeButton} onClick={handleOnCloseClick}><CloseIcon/></button>
        {children}
        <div className={styles.controlsLayout}>
            {getRights(rights, 'editMarker') && (<>
                <button className={styles.button} onClick={handleOnEditClick}>Edit</button>
                {handleOnCopyClick && <button className={styles.button} onClick={handleOnCopyClick}>Copy</button>}
                {handleOnMoveClick && <button className={styles.button} onClick={handleOnMoveClick}>Move</button>}
                {handleOnCopyToClick &&
                  <button className={styles.button} onClick={handleOnCopyToClick}>Copy To</button>}
            </>)}
            {handleOnPlayClick && <button className={styles.button} onClick={handleOnPlayClick}>Play</button>}
        </div>
        {!getRights(rights, 'hideCommercials') && <iframe
          className={styles.responsiveIframe}
          src="https://embed.creator-spring.com/widget?slug=my-store-e8a369&per=6&bg=ffffff&txtcolor=000000&currency=&page="
          title="Avdiivka Merch store powered by Spring"
          height="250"
          data-reactroot=""/>}
    </div>)
})
