import { areaService, IAreaBase, IDrawWithId } from "services/AreaService";
import { Feature } from "geojson";

export class CreateModalController {

    public updateAreaAPIRequest(area: IAreaBase, features: Feature[]) {
        const _features = features.map(feature => {
           return {
               ...feature, properties: {
                   ...feature.properties,
                   isRemovable: area.isRemovable,
                   isCollection: area.isCollection,
               }
           }
        })
        return areaService.updateAreaAPIRequest(area, _features)
    }

    public createAreaAPIRequest(area: IAreaBase) {
        areaService.createAreaAPIRequest(area).then(res => {
            if (res) areaService.addAreaToStore(res.data)
        })
    }

    public updateAreaInStore(area: IDrawWithId) {
        areaService.updateAreaInStore(area)
    }

}

export const createModalController = new CreateModalController();
