import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Popup as PopupMapBox } from "@urbica/react-map-gl";
import { drawStore, tableStore } from "stores";
import { IMarker } from "core/types";
import { Popup } from "components";
import { DeviceType } from "hooks/useDeviceType";
import { observer } from "mobx-react";

interface PopupContainerProps {
    popupInfo: IMarker | undefined
    isMove: boolean;
    deviceType: DeviceType;
    isAdsBlockOpened: boolean;
    setPopupInfo: Dispatch<SetStateAction<IMarker | undefined>>;
    setIsAdsBlockOpened: Dispatch<SetStateAction<boolean>>;
    setIsMoveMarker: Dispatch<SetStateAction<boolean>>;
    setMarkerData: Dispatch<SetStateAction<IMarker | undefined>>;
    setIsCopyMarkerToCoordinates: Dispatch<SetStateAction<boolean>>;
}

export const PopupContainer = observer(({
                                            popupInfo,
                                            isMove,
                                            deviceType,
                                            isAdsBlockOpened,
                                            setPopupInfo,
                                            setIsAdsBlockOpened,
                                            setIsMoveMarker,
                                            setMarkerData,
                                            setIsCopyMarkerToCoordinates
                                        }: PopupContainerProps) => {
    const {drawMode} = drawStore;
    useEffect(() => {
        if (drawMode !== 'static' && drawMode !== 'simple_select') {
            setPopupInfo(undefined);
        }
    }, [drawMode, setPopupInfo]);

    if (!popupInfo || isMove || !['static', 'simple_select'].includes(drawMode)) {
        return null;
    }
    return (<>
        {popupInfo && !isMove && (drawMode === 'static' || drawMode === 'simple_select')
            && <PopupMapBox
            anchor="top"
            longitude={popupInfo.coordinates.lng}
            latitude={popupInfo.coordinates.lat}
            maxWidth='920px'
            closeButton={false}
            closeOnClick={false}
          >
            <Popup
              marker={popupInfo}
              setPopupInfo={setPopupInfo}
              deviceType={deviceType}
              markerTypesData={tableStore.markerTypesData}
              isAdsBlockOpened={isAdsBlockOpened}
              setIsAdsBlockOpened={setIsAdsBlockOpened}
              setIsMoveMarker={setIsMoveMarker}
              setMarkerData={setMarkerData}
              setIsCopyMarkerToCoordinates={setIsCopyMarkerToCoordinates}
            />
          </PopupMapBox>}
    </>)
})

