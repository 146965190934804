import React, { useCallback } from "react";
import { tableService } from "services";
import { Formik } from "formik";
import { modalStore } from "stores/ModalStore";
import { ICountryPayload } from "core/types";
import { actionTypes, areaActionType, ModalType } from "core/modals";
import { IDrawWithId } from "services/AreaService";
import {
    IModalStructure,
    InitialValues,
} from "utils/getCreateModalsStructure";
import { getTableTypeByModalType } from "utils/getTablesStructure";
import { ModalContainer } from "../ModalContainer/ModalContainer";
import { createModalController } from "./CreateModal.controller";
import styles from "./CreateModal.module.scss";
import { Checkbox } from "primereact/checkbox";


interface Props {
    structure: IModalStructure;
    initialData?: IDrawWithId;
    actionType?: actionTypes;
    onClose?: (() => void);
}

export const areaActionTypes = {
    CREATE_AREA: 'CREATE_AREA' as areaActionType,
    ADD_AREA: 'ADD_AREA' as areaActionType,
    UPDATE_AREA: 'UPDATE_AREA' as areaActionType,
}

export const CreateModal: React.FC<Props> = ({structure, onClose, initialData, actionType}) => {

    const onModalCloseHandler = useCallback(() => {
        modalStore.close();
        if (onClose) onClose();
    }, [onClose]);

    return (
        <ModalContainer onClose={onModalCloseHandler}>
            <Formik<InitialValues>
                initialValues={structure.getInitialValues(initialData) as InitialValues}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    modalStore.close()
                    if (structure.modalType === ModalType.AreaInfo && initialData && initialData.id && actionType === areaActionTypes.UPDATE_AREA) {
                        return createModalController.updateAreaAPIRequest({id: initialData.id, ...values}, initialData.geoJson.features).then(res => {
                            if (res) createModalController.updateAreaInStore(res.data)
                        })
                    }

                    if (structure.modalType === ModalType.AreaInfo && actionType === areaActionTypes.CREATE_AREA) {
                        return createModalController.createAreaAPIRequest({...values})
                    }

                    return tableService.createTableRow(getTableTypeByModalType(structure.modalType), {
                        ...values
                    } as ICountryPayload)

                }}
            >
                {({values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue}) => {

                    const {isRemovable, isCollection} = values as {
                        title: string;
                        desc: string;
                        isRemovable: boolean;
                        isCollection: boolean;
                    }

                     return (<form className={styles.form} onSubmit={handleSubmit}>
                        {/* Modal for regular creation of data */}
                        {initialData && <div>Update Data Of Area #{initialData.id}</div>}
                        {actionType !== areaActionTypes.ADD_AREA && structure.fields.map(field => {
                            return <div key={field.id} className={styles.item}>
                                <label htmlFor={field.name}>{field.title}</label>
                                {React.createElement(field.component, {
                                    name: field.name,
                                    id: field.id,
                                    onChange: handleChange,
                                    onBlur: handleBlur,
                                    value: (values as any)[field.name]
                                })}
                            </div>
                        })}
                        {/* Additional field for area to determine whether area is removable and is collection or not */}
                        {structure.modalType === ModalType.AreaInfo && actionType !== areaActionTypes.CREATE_AREA && initialData?.geoJson?.features?.length && initialData?.geoJson?.features?.length > 0 ? (
                            <>
                                <div className={styles.item}>
                                    <label htmlFor='isRemovable'>Is Area Removable?</label>
                                    <Checkbox
                                        inputId="isRemovable"
                                        checked={isRemovable}
                                        onChange={(e) => setFieldValue('isRemovable', e.checked)}
                                    />
                                </div>
                                <div className={styles.item}>
                                    <label htmlFor='isCollection'>Is Area A Collection?</label>
                                    <Checkbox
                                        inputId="isCollection"
                                        checked={isCollection}
                                        onChange={(e) => setFieldValue('isCollection', e.checked)}
                                    />
                                </div>
                            </>
                        ) : null}
                        <button className={styles.button} type="submit" disabled={isSubmitting}>
                            {structure.getCreateButtonTitle(actionType)}
                        </button>
                    </form>)
                }}
            </Formik>
        </ModalContainer>
    )
}
