import { Layer, Popup as PopupMapBox } from "@urbica/react-map-gl";
import React from "react";
import { MapboxGeoJSONFeature } from "mapbox-gl";

interface HoverLayerProps {
    sourceId: string
    hoveredLayer: MapboxGeoJSONFeature | null
}

export const HoverLayer = React.memo((
    {
        sourceId,
        hoveredLayer
    }: HoverLayerProps
) => {

    if (!hoveredLayer) return null

    return (
        <>
            <Layer
                id={`hover-highlight-${sourceId}`}
                type="circle"
                source={sourceId}
                before={sourceId}
                filter={['==', ['get', 'id'], hoveredLayer.properties?.id]}
                paint={{
                    'circle-radius': 26,
                    'circle-opacity': 1,
                    'circle-color': '#ffc600'
                }}
            />
            <PopupMapBox
                anchor="bottom"
                longitude={JSON.parse(hoveredLayer.properties?.coordinates).lng}
                latitude={JSON.parse(hoveredLayer.properties?.coordinates).lat}
                maxWidth="920px"
                closeButton={false}
                closeOnClick={false}
            >
                <div>{hoveredLayer.properties?.title}</div>
            </PopupMapBox>
        </>
    )
})
