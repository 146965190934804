import React, { useState } from "react";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { IMarker } from "core/types";
import { MarkersLayer } from "./MarkersLayer/MarkersLayer";
import { HoverLayer } from "./HoverLayer/HoverLayer";
import { observer } from "mobx-react";

export interface IUnitsProps {
    sourceId: string;
    iconAllowOverlap: boolean;
    data: IMarker[];
    onMarkerClick: (marker: IMarker | null) => void;
}

export const UNITS_MARKER_TYPE_ID = 11;

export const MarkerLayers = observer(({sourceId, iconAllowOverlap, data, onMarkerClick}: IUnitsProps) => {
    const [hoveredLayer, setHoveredLayer] = useState<MapboxGeoJSONFeature | null>(null);

    return (
        <>
            <HoverLayer
                sourceId={sourceId}
                hoveredLayer={hoveredLayer}
            />
            <MarkersLayer
                sourceId={sourceId}
                data={data}
                setHoveredLayer={setHoveredLayer}
                onMarkerClick={onMarkerClick}
                iconAllowOverlap={iconAllowOverlap}
            />
        </>
    );
});
