import React, { useCallback } from "react";
import { Formik } from "formik";
// @ts-ignore
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { classNames } from 'primereact/utils';
import { mapStore } from "../../../stores";
import { coordinatesButtonController } from "./CoordinatesButton.controller";

interface ICoordinatesButtonProps {
    className: string;
}

export const CoordinatesButton = React.memo(({className}: ICoordinatesButtonProps) => {

    const handleOnCoordinatesEntered = useCallback((coordinates: string) => {
        const formattedCoordinates = coordinates.split(',')
        const latitude = parseFloat(formattedCoordinates[0]);
        const longitude = parseFloat(formattedCoordinates[1])
        if (formattedCoordinates.length === 2) {
            mapStore.onMapMove({
                latitude,
                longitude,
                zoom: 15,
            })
            coordinatesButtonController.setMarkerCoordinates([latitude, longitude])
        }
    }, [])

    return (<div className={className}>
        <Formik
            initialValues={{coordinates: ''}}
            validationSchema={Yup.object().shape({
                coordinates: Yup.string().matches(/^-?\d{1,2}\.\d+,\s*-?\d{1,3}\.\d+$/, "Only Coordinates Allowed")
            })}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(false);
                handleOnCoordinatesEntered(values.coordinates.replace(/\s+$/g, ""))
            }}
        >
            {({values, errors, handleChange, handleSubmit}) => {
                return (<form onSubmit={handleSubmit}>
                    <InputText
                        id="coordinates"
                        name="coordinates"
                        value={values.coordinates}
                        onChange={handleChange}
                        placeholder='Set Coordinates'
                        className={classNames({'p-invalid': errors.coordinates})}
                    />
                </form>)
            }}
        </Formik>
    </div>)
})
