import { IDrawProps } from "core/draws";
import { drawService } from "services/DrawService";

export class ColorsButtonControllerController {

    public setDrawProps(drawProps: IDrawProps) {
        drawService.setDrawProps(drawProps)
    }
}

export const colorsButtonControllerController = new ColorsButtonControllerController();
