import React, { Dispatch, memo, SetStateAction, useCallback } from "react";
import { IDistance } from "core/types";
import { DistanceMeasure } from "components";

interface DistanceMeasureContainerProps {
    distances: IDistance[];
    setDistances: Dispatch<SetStateAction<IDistance[]>>
}

export const DistanceMeasureContainer = memo(({distances, setDistances}: DistanceMeasureContainerProps) => {

    const removeDistance = useCallback((distance: IDistance) => {
        setDistances(distances.filter(dist => dist.id !== distance.id))
    }, [distances, setDistances])

    return <>{distances.map((distance) => {
        return <DistanceMeasure
            key={distance.miles}
            distance={distance}
            removeDistance={removeDistance}
        />
    })}</>
})
