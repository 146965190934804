import React, { Dispatch, SetStateAction, useCallback } from "react";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { Nullable } from 'primereact/ts-helpers';

interface ICalendarComponentProps {
    dates: Nullable<Date[]>
    setDates: Dispatch<SetStateAction<Nullable<Date[]>>>
    className: string
}

export const CalendarComponent = React.memo(({dates, setDates, className}: ICalendarComponentProps) => {

    const handleOnDateChange = useCallback((e: CalendarChangeEvent) => {
        const date = e.value;
        if (Array.isArray(date)) setDates(date);
    }, [setDates]);

    return (
        <div className={className}>
            <Calendar
                value={dates}
                onChange={handleOnDateChange}
                selectionMode="range"
                readOnlyInput/>
        </div>
    )
})
