import { makeAutoObservable, runInAction } from 'mobx';
import { IMarker } from "core/types";
import { tableStore } from "./TableStore";
import { getCountryInfo, getImageUrl } from "../utils";
import { mapService } from "../services";

export class MarkersStore {
    markers: IMarker[];
    units: IMarker[];
    events: IMarker[];
    uniqueData: {
        countryId: number,
        icon: string,
    }[];

    constructor() {
        this.markers = []
        this.units = []
        this.events = []
        this.uniqueData = []
        makeAutoObservable(this)
        this.addMarkerToStore = this.addMarkerToStore.bind(this)
    }

    setMarkers(data: IMarker[]) {
        runInAction(() => {
            this.markers = data.filter(marker => {
                if ((marker.type === 11 && marker.country === 1) || marker.icon === "airport"
                    || marker.icon === "uaMechanizedBrigade"
                    || marker.icon === "lightInfantryBrigade"
                    || marker.icon === "uaAviationBrigade"
                    || marker.icon === "uaArtilleryBrigade"
                    || marker.icon === "uaTankBrigade"
                    || marker.icon === "uaAntiAircraftMissileRegiment"
                    || marker.icon === "uaSpecialForcesBattalion"
                    || marker.icon === "uaMechanizedBattalion"
                    || marker.icon === "uaMotorizedBrigade"
                    || marker.icon === "uaAirAssaultBrigade"
                    || marker.icon === "uaAirMobileBrigade"
                    || marker.icon === "uaInfantryBrigade"
                    || marker.icon === "uaMarineBrigade"
                    || marker.icon === "uaMixedBrigade"
                    || marker.icon === "uaTankBattalion"
                    || marker.icon === 'uaAntiAircraftMissileBrigade'
                    || marker.icon === 'uaAssaultBrigade'
                    || marker.icon === 'uaInfantryBattalion'
                    || marker.icon === 'uaJagerBrigade'
                    || marker.icon === 'uaOperationalMotorizedBrigade'
                    || marker.icon === 'uaSpecialPoliceForce'
                    || marker.icon === 'uaSpecialPoliceForceBattalion'
                    || marker.icon === 'uaIntelligenceBattalions'
                    || marker.icon === 'uaSpecialPoliceForceRegiment'
                    || marker.icon === 'uaAirAssaultBattalion'
                    || marker.icon === 'uaMixedRegiment'
                    || marker.icon === 'uaRocketArtilleryBrigade'
                    || marker.icon === 'uaSpecialForcesRegiment'
                    || marker.icon === 'uaMixedBattalion'
                    || marker.icon === 'uaAirborneBattalion'
                    || marker.icon === 'uaSpecialForces'
                    || marker.icon === 'uaInfantryRegiment'
                    || marker.icon === 'uaMarineBattalion'
                    || marker.icon === 'uaIntelligenceBattalion'
                    || marker.icon === 'uaNavalSpecialForces'
                    || marker.icon === 'uaRapidResponseBrigade'
                    || marker.icon === 'uaVolunteerArmy'
                ) return undefined
                return marker
            });
            this.units = data.filter(marker => {
                return marker.icon === "airport"
                    || marker.icon === "uaMechanizedBrigade"
                    || marker.icon === "lightInfantryBrigade"
                    || marker.icon === "uaAviationBrigade"
                    || marker.icon === "uaArtilleryBrigade"
                    || marker.icon === "uaTankBrigade"
                    || marker.icon === "uaAntiAircraftMissileRegiment"
                    || marker.icon === "uaSpecialForcesBattalion"
                    || marker.icon === "uaMechanizedBattalion"
                    || marker.icon === "uaMotorizedBrigade"
                    || marker.icon === "uaAirAssaultBrigade"
                    || marker.icon === "uaAirMobileBrigade"
                    || marker.icon === "uaInfantryBrigade"
                    || marker.icon === "uaMarineBrigade"
                    || marker.icon === "uaMixedBrigade"
                    || marker.icon === "uaTankBattalion"
                    || marker.icon === 'uaAntiAircraftMissileBrigade'
                    || marker.icon === 'uaAssaultBrigade'
                    || marker.icon === 'uaInfantryBattalion'
                    || marker.icon === 'uaJagerBrigade'
                    || marker.icon === 'uaOperationalMotorizedBrigade'
                    || marker.icon === 'uaSpecialPoliceForce'
                    || marker.icon === 'uaSpecialPoliceForceBattalion'
                    || marker.icon === 'uaIntelligenceBattalions'
                    || marker.icon === 'uaSpecialPoliceForceRegiment'
                    || marker.icon === 'uaAirAssaultBattalion'
                    || marker.icon === 'uaMixedRegiment'
                    || marker.icon === 'uaRocketArtilleryBrigade'
                    || marker.icon === 'uaSpecialForcesRegiment'
                    || marker.icon === 'uaMixedBattalion'
                    || marker.icon === 'uaAirborneBattalion'
                    || marker.icon === 'uaSpecialForces'
                    || marker.icon === 'uaInfantryRegiment'
                    || marker.icon === 'uaMarineBattalion'
                    || marker.icon === 'uaIntelligenceBattalion'
                    || marker.icon === 'uaNavalSpecialForces'
                    || marker.icon === 'uaRapidResponseBrigade'
                    || marker.icon === 'uaVolunteerArmy'
            });
        })
    }

    setEvents(data: IMarker[]) {
        runInAction(async () => {
            this.events = data;
            this.uniqueData = Array.from(
                new Map(
                    data.map(item => [`${item.country}_${item.icon}`, {countryId: item.country, icon: item.icon}])
                ).values()
            );
            for (const data of this.uniqueData) {
                const countryInfo = getCountryInfo(data.countryId)
                const countryCode = countryInfo ? countryInfo.titleNative : '00';
                const image = getImageUrl(countryCode, data.icon)
                await mapService.loadImage(image.url, image.type, false);
            }
        })
    }

    public addMarkerToStore(marker: IMarker) {
        const {markerTypesData} = tableStore;
        const eventsMarkerTypeId = markerTypesData.find(type => type.title === 'Events')?.id
        runInAction(() => {
            if (marker.type === eventsMarkerTypeId) {
                this.events.push(marker)
            }
            if (marker.type !== eventsMarkerTypeId) {
                if ((marker.type === 11 && marker.country === 1) || marker.icon === "airport"
                    || marker.icon === "uaMechanizedBrigade"
                    || marker.icon === "lightInfantryBrigade"
                    || marker.icon === "uaAviationBrigade"
                    || marker.icon === "uaArtilleryBrigade"
                    || marker.icon === "uaTankBrigade"
                    || marker.icon === "uaAntiAircraftMissileRegiment"
                    || marker.icon === "uaSpecialForcesBattalion"
                    || marker.icon === "uaMechanizedBattalion"
                    || marker.icon === "uaMotorizedBrigade"
                    || marker.icon === "uaAirAssaultBrigade"
                    || marker.icon === "uaAirMobileBrigade"
                    || marker.icon === "uaInfantryBrigade"
                    || marker.icon === "uaMarineBrigade"
                    || marker.icon === "uaMixedBrigade"
                    || marker.icon === "uaTankBattalion"
                    || marker.icon === 'uaAntiAircraftMissileBrigade'
                    || marker.icon === 'uaAssaultBrigade'
                    || marker.icon === 'uaInfantryBattalion'
                    || marker.icon === 'uaJagerBrigade'
                    || marker.icon === 'uaOperationalMotorizedBrigade'
                    || marker.icon === 'uaSpecialPoliceForce'
                    || marker.icon === 'uaSpecialPoliceForceBattalion'
                    || marker.icon === 'uaIntelligenceBattalions'
                    || marker.icon === 'uaSpecialPoliceForceRegiment'
                    || marker.icon === 'uaAirAssaultBattalion'
                    || marker.icon === 'uaMixedRegiment'
                    || marker.icon === 'uaRocketArtilleryBrigade'
                    || marker.icon === 'uaSpecialForcesRegiment'
                    || marker.icon === 'uaMixedBattalion'
                    || marker.icon === 'uaAirborneBattalion'
                    || marker.icon === 'uaSpecialForces'
                    || marker.icon === 'uaInfantryRegiment'
                    || marker.icon === 'uaMarineBattalion'
                    || marker.icon === 'uaIntelligenceBattalion'
                    || marker.icon === 'uaNavalSpecialForces'
                    || marker.icon === 'uaRapidResponseBrigade'
                    || marker.icon === 'uaVolunteerArmy'
                ) {
                    this.units.push(marker)
                } else {
                    this.markers.push(marker);
                }

            }
        })
    }

    public updateMarkersInStore(data: IMarker) {
        runInAction(() => {
            const marker: IMarker | undefined = this.markers.find(marker => marker.id === data.id)
            const event: IMarker | undefined = this.events.find(eventLayer => eventLayer.id === data.id)
            const unit: IMarker | undefined = this.units.find(unit => unit.id === data.id)

            if (marker) marker.coordinates = data.coordinates;
            if (event) event.coordinates = data.coordinates;
            if (unit) unit.coordinates = data.coordinates;
        })
    }

    public removeMarkerFromStore(ids: number[] | string[]) {
        runInAction(() => {
            ids.forEach(id => {
                this.markers = this.markers.filter(marker => marker.id !== id)
                this.events = this.events.filter(event => event.id !== id)
                this.units = this.units.filter(unit => unit.id !== id)
            });
        })
    }
}

export const markersStore = new MarkersStore();
