import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from "mobx-react";
import cn from "clsx";
import LinesEllipsis from "react-lines-ellipsis";
import { INotification, notificationStore } from 'stores/NotificationStore';
import styles from './Notifications.module.scss';

interface IProps {
    notification: INotification;
}

const Notification = ({notification}: IProps) => {
    const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);

    const id = notification.id;
    const {content, type} = notification;

    const [width, setWidth] = useState<string | number>('auto');
    const [closing, setClosing] = useState(false);

    const timerRef: MutableRefObject<any> = useMemo(() => {
        return {current: undefined}
    }, [])

    const notificationClasses = cn([
        styles.notification, {
            [styles.notification_closing]: closing,
            [styles.notification_error]: type === 'error',
            [styles.notification_success]: type === 'success',
            [styles.notification_warning]: type === 'warning',
            [styles.notification_default]: type === 'default',
        }
    ]);

    const onClose = useCallback(() => {
        clearTimeout(timerRef.current);

        if (ref.current) setWidth(ref.current.clientWidth);
        setClosing(true);
    }, [timerRef])

    useEffect(() => {

        timerRef.current = setTimeout(onClose, 3000);

    }, [timerRef, onClose]);


    const onAnimationEnd = () => notificationStore.remove({payload: {id}});

    return (
        <div
            onAnimationEnd={onAnimationEnd} style={{width}} ref={ref} onClick={() => onClose()}
            className={notificationClasses}
        ><img className={styles.image} src={`/images/notifications/${type}.svg`} alt=''/>
            <LinesEllipsis
                text={content}
                maxLine={6}
                ellipsis='...'
                trimRight
                basedOn='letters'
                className={styles.content}
            />
        </div>
    );
};

export const Notifications = observer(() => {
    const {notifications} = notificationStore;

    const notificationsClasses = cn([
        styles.notifications,
        'bvi-no-styles'
    ]);

    if (notifications.length > 0 && false) return (
        <div suppressHydrationWarning={true}>
            <div className={notificationsClasses}>
                {notifications.map((n, index) => <Notification key={index} notification={n}/>)}
            </div>
        </div>
    );

    return <div/>
})
