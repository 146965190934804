import React, { useMemo } from "react";
import { Layer, Source } from "@urbica/react-map-gl";
import { areaStore } from "stores/AreaStore";
import { observer } from "mobx-react";
import { Feature, Geometry } from "geojson";

const STRONG_HOLD_PAINT_STYLE = {
    'line-color': '#000000',
    'line-width':3,
};

export const Strongholds = observer(() => {
    const {areas} = areaStore;

    const strongHolds = useMemo(() => areas.flatMap(area => [...area.geoJson.features]).filter((feature): feature is Feature<Geometry> => {
        return feature.type === "Feature" && (feature.geometry.type === 'LineString' || feature.geometry.type === 'MultiLineString');
    }), [areas])

    if (strongHolds) {
        return (
            <div>
                <Source
                    id='strongholds'
                    type='geojson'
                    data={{
                        type: 'FeatureCollection',
                        features: strongHolds,
                    }}
                />
                <Layer
                    id='strongholds'
                    type='line'
                    before='units'
                    source='strongholds'
                    filter={['!=', ['get', 'isVisible'], false]}
                    paint={STRONG_HOLD_PAINT_STYLE}
                />
            </div>
        );
    }

    return null;
})
