import { configure, makeObservable, runInAction } from 'mobx';
import { action, observable } from 'mobx';
import { TableProps, TableType } from 'core/tables';
import { ICountry, IFile, IMarker, IMarkerType, IMeta } from "core/types";
import {
    CountryResponse,
    FilesResponse,
    MarkersResponse,
    MarkerTypeResponse,
    UnlinkedFileResponse
} from "../interfaces/Response";

configure({
    useProxies: 'never',
});

export class TableStore {
    public tableProps: TableProps | null = null;
    public countriesData: ICountry[] = [];
    public markerTypesData: IMarkerType[] = [];
    public filesData: IFile[] = [];
    public unlinkedFilesData: string[] = [];
    public markersData: IMarker[] | [] = [];
    public meta: IMeta = {
        page: 0,
        take: 5,
        itemCount: 0,
        pageCount: null,
        hasPreviousPage: false,
        hasNextPage: false,
    }


    constructor() {
        makeObservable(this, {
            tableProps: observable,
            countriesData: observable,
            markerTypesData: observable,
            markersData: observable,
            filesData: observable,
            unlinkedFilesData: observable,
            meta: observable,
            open: action,
            close: action,
            reset: action,
            getData: action,
        });

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.setDataToTable = this.setDataToTable.bind(this);
        this.getData = this.getData.bind(this);
    }

    open(props: TableProps) {
        runInAction(() => {
            this.tableProps = props;
        })
    }

    close() {
        this.reset();
    }

    reset() {
        runInAction(() => {
            this.tableProps = null;
        })
    }

    setDataToTable(tableType: TableType, data: CountryResponse[] | FilesResponse[] | MarkerTypeResponse[] | MarkersResponse[] | UnlinkedFileResponse[]) {
        runInAction(() => {
            if (tableType === TableType.CountriesTable) this.countriesData = data as CountryResponse[]
            if (tableType === TableType.MarkerTypesTable) this.markerTypesData = data as MarkerTypeResponse[]
            if (tableType === TableType.MarkersTable) this.markersData = data as MarkersResponse[]
        })
    }

    setMeta(meta: IMeta) {
        runInAction(() => {
            this.meta = meta
        })
    }

    getData(tableType: TableType) {
        if (tableType === TableType.CountriesTable) return this.countriesData
        if (tableType === TableType.MarkerTypesTable) return this.markerTypesData
        if (tableType === TableType.MarkersTable) return this.markersData
        return this.unlinkedFilesData
    }

    getMeta() {
        return this.meta
    }
}

export const tableStore = new TableStore();
