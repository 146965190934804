import { makeObservable, observable, runInAction } from "mobx";

export class ColorsService {

    color: string = '#000';

    constructor() {
        makeObservable(this, {
            color: observable,
        });
    }

    public setDrawingColor(color: string) {
        runInAction(() => {
            this.color = color;
        })
    }
}

export const colorsService = new ColorsService();
