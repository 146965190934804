import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { PrimeReactProvider } from "primereact/api";
import { HashRouter } from 'react-router-dom';
import { AppAuthProvider } from './contexts/AppAuthProvider';
import { AppRightsProvider } from "./contexts/AppRightsProvider";
import { Modal, Tables } from './components';
import App from './App';

import './index.scss';

ReactDOM.createRoot(document.getElementById('root')!).render(
    <HashRouter>
        <AppAuthProvider>
            <AppRightsProvider>
                <PrimeReactProvider>
                    <App/>
                    <Modal/>
                    <Tables/>
                </PrimeReactProvider>
            </AppRightsProvider>
        </AppAuthProvider>
    </HashRouter>
);
