import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { meStore, modalStore } from "stores";
import { authService } from "services";
import { Card } from 'primereact/card';
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from 'primereact/tabview';
import { ModalContainer } from "../";
import { Button } from "primereact/button";

interface AccountInfoProps {
    onClose?: () => void;
}

export const AccountInfoModal: React.FC<AccountInfoProps> = observer(({onClose}) => {
    const {me} = meStore;

    const onModalCloseHandler = useCallback(() => {
        modalStore.close();
        if (onClose) onClose();
    }, [onClose]);

    const handleOnLogoutClick = () => {
        authService.logout().then(() => modalStore.reset())
    }

    return <ModalContainer onClose={onModalCloseHandler}>
        <TabView>
            <TabPanel header="Account">
                <Card title='Account Info'>
                    <Panel header="Email">
                        <p className="m-0">
                            {me?.email}
                        </p>
                    </Panel>
                    <Panel header="Username">
                        <p className="m-0">
                            {me?.username}
                        </p>
                    </Panel>
                    <Panel header="Full Name">
                        <p className="m-0">
                            {me?.fullName}
                        </p>
                    </Panel>
                    <Panel header="Role">
                        <p className="m-0">
                            {me?.roles[0]}
                        </p>
                    </Panel>
                </Card>
                <div className="w-full md:w-5 flex align-items-center justify-content-center py-5">
                    <Button label="Log out" icon="pi pi-user-plus" className="p-button-success"
                            onClick={handleOnLogoutClick}/>
                </div>
            </TabPanel>
        </TabView>
    </ModalContainer>
})
