import { IForceType } from "core/types";
import {
    AirplaneComponent,
    AirportComponent,
    DroneComponent,
    GlobalHawkComponent,
    HelicopterComponent,
    NavyDroneComponent,
    RadarComponent,
    ShipComponent,
    SoldierComponent,
    TankComponent
} from "components/Marker/RenderMarker/Forces/Forces";

export const FORCES_TYPES: IForceType[] = [
    {
        type: 'soldier',
        value: 'soldier',
        name: 'Soldier',
        component: SoldierComponent
    }, {
        type: 'tank',
        value: 'tank',
        name: 'Tank',
        component: TankComponent
    }, {
        type: 'helicopter',
        value: 'helicopter',
        name: 'Helicopter',
        component: HelicopterComponent
    }, {
        type: 'ship',
        value: 'ship',
        name: 'Ship',
        component: ShipComponent
    }, {
        type: 'globalHawk',
        value: 'globalHawk',
        name: 'Global Hawk',
        component: GlobalHawkComponent
    }, {
        type: 'navyDrone',
        value: 'navyDrone',
        name: 'Navy Drone',
        component: NavyDroneComponent
    }, {
        type: 'drone',
        value: 'drone',
        name: 'Drone',
        component: DroneComponent
    }, {
        type: 'airplane',
        value: 'airplane',
        name: 'Airplane',
        component: AirplaneComponent
    }, {
        type: 'radar',
        value: 'radar',
        name: 'Radar',
        component: RadarComponent
    }, {
        type: 'airport',
        value: 'airport',
        name: 'Airport',
        component: AirportComponent
    }
]
