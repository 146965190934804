import { mapStore } from "stores";

export class CoordinatesMarkerController {

    public setMarkerCoordinates(markerCoordinates: [number, number] | undefined) {
        mapStore.setMarkerCoordinates(markerCoordinates)
    }
}

export const coordinatesMarkerController = new CoordinatesMarkerController();
