import { MarkerType } from "core/types";


export const SHOW_ZOOM_10 = 10
export const SHOW_ZOOM_9 = 9
export const SHOW_ZOOM_8 = 8
export const SHOW_ZOOM_7 = 7
export const SHOW_ZOOM_6 = 6
export const SHOW_ZOOM_5 = 5
export const SHOW_ZOOM_4 = 4
export const SHOW_ZOOM_3 = 3
export const SHOW_ZOOM_2 = 2
export const SHOW_ZOOM_1 = 1
export const SHOW_ZOOM_0 = 0

export const getMarkerZoomView = (markerType: MarkerType | 'info' | undefined, icon: string | undefined) => {

    if (markerType === 'Events') return SHOW_ZOOM_5
    if (markerType === 'forces' && icon !== 'globalHawk' && icon !== 'ship') return SHOW_ZOOM_7
    if (markerType === 'forces' && icon === 'globalHawk') return SHOW_ZOOM_5
    if (markerType === 'forces' && icon === 'ship') return SHOW_ZOOM_5
    if (markerType === 'unit') return SHOW_ZOOM_6
    if (markerType === 'airport') return SHOW_ZOOM_5
    if (markerType === 'info') return SHOW_ZOOM_6

    return SHOW_ZOOM_0
}
