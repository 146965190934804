import React, { Dispatch, SetStateAction, useCallback } from "react";
import { modalStore } from "stores";
import { ModalType } from "core/modals";

interface AccountInfoButtonProps {
    className: string;
    setSideBarVisible?: Dispatch<SetStateAction<boolean>>;
}

export const AccountInfoButton = React.memo(({ className, setSideBarVisible }: AccountInfoButtonProps) => {
    const handleOnAccountButtonClick = useCallback(() => {
        modalStore.open({
            type: ModalType.AccountInfo,
            actionType: undefined,
        });
        setSideBarVisible?.(false);
    }, [setSideBarVisible]);

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleOnAccountButtonClick();
        }
    }, [handleOnAccountButtonClick]);

    return <div className={className}>
        <img
            src='images/channel.png'
            alt='Channel'
            role="button"
            onClick={handleOnAccountButtonClick}
            onKeyDown={handleKeyDown}
            tabIndex={0}
        />
    </div>
});
