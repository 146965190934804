import React, { useMemo } from "react";
import { Popup as PopupMapBox } from "@urbica/react-map-gl";
import { IDistance } from "core/types";
import styles from './DistanceMeasure.module.scss'

interface DistanceProps {
    distance: IDistance;
    removeDistance: (distance: IDistance) => void;
}

export const DistanceMeasure = React.memo(({distance, removeDistance}: DistanceProps) => {

    const lastCoordinate = useMemo(() => distance.coordinates[distance.coordinates.length - 1], [distance])

    return (
        <PopupMapBox
            anchor="top"
            longitude={lastCoordinate[0]}
            latitude={lastCoordinate[1]}
            maxWidth='100px'
            closeButton={true}
            closeOnClick={false}
            onClose={() => removeDistance(distance)}
        >
            <div className={styles.layout}>
                <div>{`${distance.kilometers.toFixed(2)} km`}</div>
                <div>{`${distance.miles.toFixed(2)} mi`}</div>
            </div>
        </PopupMapBox>
    )
})
