import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { MapMouseEvent } from "mapbox-gl";
import { Layer, Source } from "@urbica/react-map-gl";
import { Feature, Geometry } from 'geojson';
import { areaStore } from "stores/AreaStore";
import { territoryLayersController } from "./TerritoryLayers.controller";

export const TerritoryLayers = observer(() => {
    const { areas, territories } = areaStore;
    const [hoveredFeatureId, setHoveredFeatureId] = useState<string | null>(null);

    const onHover = useCallback((event: MapMouseEvent & { features?: Feature<Geometry>[] }) => {
        if (event.features && event.features.length > 0) {
            setHoveredFeatureId(event.features[0]?.properties?.id as string || null);
        }
    }, []);

    const fillOpacity = useMemo(() => ['case',
        ['==', ['get', 'id'], hoveredFeatureId], 0.3,
        ['boolean', ['get', 'isCollection'], false], 0.2,
        0.3
    ], [hoveredFeatureId]);

    useEffect(() => {
        if(areas.length > 0) {
            territoryLayersController.setTerritories()
        }
    }, [areas])

    return (
        <div>
            <Source
                id='territories'
                type='geojson'
                data={{
                    type: "FeatureCollection",
                    features: territories
                }}
            />
            <Layer
                id='territories-fill'
                type='fill'
                before='units'
                source='territories'
                filter={['!=', ['get', 'isVisible'], false]}
                paint={{
                    'fill-color': ['coalesce', ['get', 'color'], '#fff'],
                    'fill-opacity': fillOpacity
                }}
                onHover={onHover}
            />
            <Layer
                id='territories-outline'
                type='line'
                source='territories'
                before='units'
                filter={['all', ['!=', ['get', 'isVisible'], false], ['==', ['get', 'isCollection'], true]]}
                paint={{
                    'line-color': ['coalesce', ['get', 'color'], '#000'],
                    'line-width': 1
                }}
            />
        </div>
    );
});
