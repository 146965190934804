export const createDrawStyles = (color: string) => {

    return [{
        'id': 'gl-draw-polygon-fill-inactive',
        'type': 'fill',
        'filter': ['all', ['==', 'active', 'false'], ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
        'paint': {
            'fill-color': color,
            'fill-outline-color': color,
            'fill-opacity': 0.0
        }
    }, {
        'id': 'gl-draw-polygon-fill-inactive-colored',
        'type': 'fill',
        'filter': ['all', ['==', 'active', 'false'], ['==', '$type', 'Polygon'], ['!=', 'mode', 'static'], ['has', 'user_color']],
        'paint': {
            'fill-color': ['get', 'user_color'],
            'fill-outline-color': ['get', 'user_color'],
            'fill-opacity': 0.3
        }
    }, {
        'id': 'gl-draw-polygon-fill-active',
        'type': 'fill',
        'filter': ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
        'paint': {
            'fill-color': color,
            'fill-outline-color': color,
            'fill-opacity': 0.3
        }
    }, {
        'id': 'gl-draw-polygon-fill-active-colored',
        'type': 'fill',
        'filter': ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon'], ['has', 'user_color']],
        'paint': {
            'fill-color': ['get', 'user_color'],
            'fill-outline-color': ['get', 'user_color'],
            'fill-opacity': 0.3
        }
    }, {
        'id': 'gl-draw-polygon-midpoint',
        'type': 'circle',
        'filter': ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
        'paint': {
            'circle-radius': 3,
            'circle-color': color
        }
    }, {
        'id': 'gl-draw-polygon-stroke-inactive',
        'type': 'line',
        'filter': ['all', ['==', 'active', 'false'], ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': color,
            'line-width': 2
        }
    }, {
        'id': 'gl-draw-polygon-stroke-active',
        'type': 'line',
        'filter': ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': color,
            'line-dasharray': [0.2, 2],
            'line-width': 2
        }
    }, {
        'id': 'gl-draw-line-inactive',
        'filter': ['all', ['==', 'active', 'false'], ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
        'type': 'line',
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': color,
            'line-width': 4
        }
    },{
        'id': 'gl-draw-line-inactive-arrow',
        'filter': ['all', ['==', 'active', 'false'], ['==', '$type', 'LineString'], ['!=', 'mode', 'static'], ['has', 'user_arrowMode']],
        'type': 'symbol',
        'layout':{
            'icon-image': 'arrowDirection',
            'icon-rotation-alignment': 'map',
            // 'symbol-placement': 'line-center',
            'icon-allow-overlap': true,
            'symbol-placement': 'line',
            'symbol-spacing': 30
        },
        'paint': {
            'icon-color': ['get', 'user_color'],
            'icon-halo-color': color,
            'icon-halo-width': 5
        }
    }, {
        'id': 'gl-draw-line-inactive-colored',
        'filter': ['all', ['==', 'active', 'false'], ['==', '$type', 'LineString'], ['!=', 'mode', 'static'], ['has', 'user_color']],
        'type': 'line',
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': ['get', 'user_color'],
            'line-width': 4
        },
    }, {
        'id': 'gl-draw-line-active',
        'type': 'line',
        'filter': ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': color,
            'line-dasharray': [0.2, 2],
            'line-width': 4
        }
    }, {
        'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
        'type': 'circle',
        'filter': ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
        'paint': {
            'circle-radius': 5,
            'circle-color': '#fff'
        }
    },
    //     {
    //     'id': 'gl-draw-polygon-and-line-vertex-inactive',
    //     'type': 'circle',
    //     'filter': ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
    //     'paint': {
    //         'circle-radius': 3,
    //         'circle-color': '#fbb03b'
    //     }
    // },
        {
        'id': 'gl-draw-polygon-and-line-vertex-inactive',
        'type': 'circle',
        'filter': ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
        'paint': {
            'circle-radius': 3,
            'circle-color': '#fbb03b'
        }
    }, {
        'id': 'gl-draw-point-point-stroke-inactive',
        'type': 'circle',
        'filter': ['all', ['==', 'active', 'false'], ['==', '$type', 'Point'], ['==', 'meta', 'feature'], ['!=', 'mode', 'static']],
        'paint': {
            'circle-radius': 5,
            'circle-opacity': 1,
            'circle-color': '#fff'
        }
    }, {
        'id': 'gl-draw-point-inactive',
        'type': 'circle',
        'filter': ['all', ['==', 'active', 'false'], ['==', '$type', 'Point'], ['==', 'meta', 'feature'], ['!=', 'mode', 'static']],
        'paint': {
            'circle-radius': 3,
            'circle-color': '#3bb2d0'
        }
    }, {
        'id': 'gl-draw-point-stroke-active',
        'type': 'circle',
        'filter': ['all', ['==', '$type', 'Point'], ['==', 'active', 'true'], ['!=', 'meta', 'midpoint']],
        'paint': {
            'circle-radius': 7,
            'circle-color': '#fff'
        }
    }, {
        'id': 'gl-draw-point-active',
        'type': 'circle',
        'filter': ['all', ['==', '$type', 'Point'], ['!=', 'meta', 'midpoint'], ['==', 'active', 'true']],
        'paint': {
            'circle-radius': 5,
            'circle-color': '#fbb03b'
        }
    }, {
        'id': 'gl-draw-polygon-fill-static',
        'type': 'fill',
        'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
        'paint': {
            'fill-color': '#404040',
            'fill-outline-color': '#404040',
            'fill-opacity': 0.0
        }
    },
        {
            'id': 'gl-draw-polygon-fill-static-colored',
            'type': 'fill',
            'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon'], ['has', 'user_color']],
            'paint': {
                'fill-color': ['get', 'user_color'],
                'fill-outline-color': ['get', 'user_color'],
                'fill-opacity': 0.3
            }
        }, {
            'id': 'gl-draw-polygon-stroke-static',
            'type': 'line',
            'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
            'layout': {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint': {
                'line-color': '#404040',
                'line-width': 2
            }
        }, {
            'id': 'gl-draw-polygon-stroke-static-colored',
            'type': 'line',
            'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon'], ['has', 'user_color']],
            'layout': {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint': {
                'line-color': ['get', 'user_color'],
                'line-width': 2
            }
        }, {
            'id': 'gl-draw-line-static',
            'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
            'type': 'line',
            'layout': {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint': {
                'line-color': '#404040',
                'line-width': 4
            }
        },{
            'id': 'gl-draw-line-static-arrow',
            'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString'], ['has', 'user_arrowMode']],
            'type': 'symbol',
            'layout':{
                'icon-image': 'arrowDirection',
                'icon-rotation-alignment': 'map',
                // 'symbol-placement': 'line-center',
                'icon-allow-overlap': true,
                'symbol-placement': 'line',
                'symbol-spacing': 30
            },
            'paint': {
                'icon-color': ['get', 'user_color'],
                'icon-halo-color': color,
                'icon-halo-width': 5
            }
        },
        {
            'id': 'gl-draw-line-active-arrow',
            'filter': ['all', ['==', '$type', 'LineString'], ['has', 'user_arrowMode'], ['==', 'active', 'true']],
            'type': 'symbol',
            'layout':{
                'icon-image': 'arrowDirection',
                'icon-rotation-alignment': 'map',
                // 'symbol-placement': 'line-center',
                'icon-allow-overlap': true,
                'symbol-placement': 'line',
                'symbol-spacing': 30
            },
            'paint': {
                'icon-color': ['get', 'user_color'],
                'icon-halo-color': color,
                'icon-halo-width': 5
            }
        },
        {
            'id': 'gl-draw-line-static-colored',
            'type': 'line',
            'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString'], ['has', 'user_color']],
            'layout': {
                'line-cap': 'round',
                'line-join': 'round'
            },
            'paint': {
                'line-color': ['get', 'user_color'],
                'line-width': 4
            }
        }, {
            'id': 'gl-draw-point-static',
            'type': 'circle',
            'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
            'paint': {
                'circle-radius': 5,
                'circle-color': '#404040'
            }
        }];
}
