import { makeObservable, observable, runInAction } from 'mobx';

export class LocalStorageService {
    localStorageAccessToken: string | null;
    localStorageRefreshToken: string | null;

    localStorage: Storage;

    constructor(localStorage: Storage) {
        makeObservable(this, {
            localStorageAccessToken: observable,
            localStorageRefreshToken: observable,
            localStorage: observable,
        });

        this.localStorage = localStorage;
        this.localStorageAccessToken = this.getLocalStorageAccessToken();
        this.localStorageRefreshToken = this.getLocalStorageRefreshToken();
        this.getLocalStorageAccessToken = this.getLocalStorageAccessToken.bind(this)
        this.getLocalStorageRefreshToken = this.getLocalStorageRefreshToken.bind(this)
    }

    getLocalStorageAccessToken() {
        return this.localStorage.getItem('accessToken');
    }

    getLocalStorageRefreshToken() {
        return this.localStorage.getItem('refreshToken');
    }

    setLocalStorageAccessToken(value: string) {
        runInAction(() => this.localStorage.setItem('accessToken', value));
    }

    setLocalStorageRefreshToken(value: string) {
        runInAction(() => this.localStorage.setItem('refreshToken', value));
    }

}

export const localStorageService = new LocalStorageService(localStorage);
