import React, { createContext, ReactNode, useMemo } from 'react';
import { Rights } from "core/types";
import { meStore } from "../stores";
import { observer } from "mobx-react";

export interface RightsContextValue {
    rights: Rights;
}

export const RightContext = createContext<RightsContextValue>({rights: 'user'});

export const AppRightsProvider: React.FC<{
    children: ReactNode;
}> = observer(({children}) => {

    const {me} = meStore;
    const rights = useMemo(() => me && me.roles && me.roles.length > 0 ? me.roles[0] : 'user', [me])

    return (
        <RightContext.Provider
            value={{
                rights,
            }}
        >
            {children}
        </RightContext.Provider>
    );
})

export default RightContext;
