import {
    LeaderWarriorComponent,
    Unit,
    Events,
    Forces
} from "components/Marker/RenderMarker";
import { IColor, UnitTypes } from "core/types";
import { MemoExoticComponent, ReactNode } from "react";

interface IComponentProps {
    id: number,
    icon: string | UnitTypes,
    markerTypeTitle: string,
    titleNative: string,
    imageClasses: string,
    color: IColor,
    withFlag: boolean,
}

interface IMarkerIconType {
    type: string,
    name: string,
    component: MemoExoticComponent<(props: IComponentProps) => ReactNode>,
}

export const MARKER_ICON_TYPES: IMarkerIconType[] = [
    {
        type: 'Leader',
        name: 'Leader',
        component: LeaderWarriorComponent
    }, {
        type: 'Warrior',
        name: 'Warrior',
        component: LeaderWarriorComponent
    }, {
        type: 'Events',
        name: 'Events',
        component: Events,
    }, {
        type: 'unit',
        name: 'Unit',
        component: Unit,
    }, {
        type: 'forces',
        name: 'Forces',
        component: Forces,
    }
]
