import React from "react";
import { UnitTypes } from "core/types";
import { NavyDrone, Ship } from "assets/icons/icons";
import styles from "../../Marker.module.scss";

interface IForces {
    icon: string | UnitTypes,
    imageClasses: string;
    titleNative: string;
    withFlag: boolean;
}

export const Forces = React.memo(({icon, imageClasses, titleNative, withFlag = false}: IForces) => {
    if (icon === 'soldier') {
        return <SoldierComponent
            imageClasses={imageClasses}
            countryTitleNative={titleNative}
            withFlag={withFlag}
        />
    }

    if (icon === 'tank') {
        return <TankComponent
            imageClasses={imageClasses}
            countryTitleNative={titleNative}
            withFlag={withFlag}
        />
    }

    if (icon === 'helicopter') {
        return <HelicopterComponent
            imageClasses={imageClasses}
            countryTitleNative={titleNative}
            withFlag={withFlag}
        />
    }
    if (icon === 'ship') {
        return <ShipComponent
            countryTitleNative={titleNative}
            withFlag={withFlag}
        />
    }

    if (icon === 'globalHawk') {
        return <GlobalHawkComponent
            imageClasses={imageClasses}
            countryTitleNative={titleNative}
            withFlag={withFlag}
        />
    }

    if (icon === 'navyDrone') {
        return <NavyDroneComponent
            countryTitleNative={titleNative}
            withFlag={withFlag}
        />
    }


    if (icon === 'drone') {
        return <DroneComponent
            countryTitleNative={titleNative}
            withFlag={withFlag}
        />
    }

    if (icon === 'airplane') {
        return <AirplaneComponent
            imageClasses={imageClasses}
            countryTitleNative={titleNative}
            withFlag={withFlag}
        />
    }

    if (icon === 'radar') {
        return <RadarComponent
            imageClasses={imageClasses}
            countryTitleNative={titleNative}
            withFlag={withFlag}
        />
    }

    if (icon === 'airport') {
        return <AirportComponent
            imageClasses={imageClasses}
        />
    }
    return <></>
})

export interface IForceProps {
    imageClasses?: string;
    countryTitleNative?: string;
    withFlag?: boolean;
}

export const SoldierComponent = ({imageClasses, countryTitleNative = 'ru', withFlag}: IForceProps): JSX.Element => {
    return <div className={imageClasses}>
        {withFlag && <img className={styles.images_country} src={`flags/${countryTitleNative}.png`}
                          alt={`flags/${countryTitleNative}.png`}/>}
        <img className={styles.images_soldier} src='images/units/soldier.png' alt='images/units/soldier.png'/>
    </div>
}

export const TankComponent = ({imageClasses, countryTitleNative = 'ru', withFlag}: IForceProps) => {
    return <div className={imageClasses}>
        {withFlag &&
        <img className={styles.images_country} src={`flags/${countryTitleNative}.png`} alt={countryTitleNative}/>}
        <div className={styles.images_tankWrapper}>
            <img className={styles.images_tank} src={`images/units/tank.png`} alt={countryTitleNative}/>
        </div>
    </div>
}

export const HelicopterComponent = ({imageClasses, countryTitleNative = 'ru', withFlag}: IForceProps) => {
    return <div className={imageClasses}>
        {withFlag &&
        <img className={styles.images_country} src={`flags/${countryTitleNative}.png`} alt={countryTitleNative}/>}
        <div className={styles.images_helicopterWrapper}>
            <img className={styles.images_helicopter} src={`images/units/helicopter.png`}
                 alt={`images/units/helicopter.png`}/>
        </div>
    </div>
}

export const ShipComponent = ({imageClasses, countryTitleNative = 'ru', withFlag}: IForceProps) => {
    return <div className={imageClasses}>
        <div className={styles.images_shipWrapper}>
            <Ship/>
        </div>
        {withFlag && <img className={styles.images} src={`flags/${countryTitleNative}.png`}
                          alt={`flags/${countryTitleNative}.png`}/>}
    </div>
}

export const GlobalHawkComponent = ({imageClasses, countryTitleNative = 'ru', withFlag}: IForceProps) => {
    return <div className={imageClasses}>
        {withFlag && <img className={styles.images_country} src={`flags/${countryTitleNative}.png`}
                          alt={`flags/${countryTitleNative}.png`}/>}
        <div className={styles.images_tankWrapper}>
            <img className={styles.images_globalHawk} src={`images/units/globalHawk.png`}
                 alt={`images/units/globalHawk.png`}/>
        </div>
    </div>
}

export const NavyDroneComponent = ({imageClasses, countryTitleNative = 'ru', withFlag}: IForceProps) => {
    return <div className={imageClasses}>
        <div className={styles.images_navyDroneWrapper}>
            <NavyDrone/>
        </div>
        {withFlag && <img className={styles.images} src={`flags/${countryTitleNative}.png`}
                          alt={`flags/${countryTitleNative}.png`}/>}
    </div>
}

export const DroneComponent = ({imageClasses, countryTitleNative = 'ru', withFlag}: IForceProps) => {
    return <div className={imageClasses}>
        <img className={styles.images_drones} src={`images/units/drone.png`} alt={`images/units/drone.png`}/>
        {withFlag && <img className={styles.images} src={`flags/${countryTitleNative}.png`}
                          alt={`flags/${countryTitleNative}.png`}/>}
    </div>
}

export const AirplaneComponent = ({imageClasses, countryTitleNative = 'ru', withFlag}: IForceProps) => {
    return <div className={imageClasses}>
        {withFlag && <img className={styles.images_country} src={`flags/${countryTitleNative}.png`}
                          alt={`flags/${countryTitleNative}.png`}/>}
        <div className={styles.images_airPlaneWrapper}>
            <img className={styles.images_airplane} src={`images/units/airplane.png`}
                 alt={`images/units/airplane.png`}/>
        </div>
    </div>
}
export const RadarComponent = ({imageClasses, countryTitleNative = 'ru', withFlag}: IForceProps) => {
    return <div className={imageClasses}>
        {withFlag && < img className={styles.images_country} src={`flags/${countryTitleNative}.png`}
                           alt={`flags/${countryTitleNative}.png`}/>}
        <img className={styles.images_drones} src={`images/units/radar.png`} alt={`images/units/radar.png`}/>
    </div>
}

export const AirportComponent = ({imageClasses}: IForceProps) => {
    return <div className={imageClasses}>
        <img className={styles.images} src={`images/units/airport.png`} alt={`images/units/airport.png`}/>
    </div>
}
