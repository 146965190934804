import { areaService, tableService } from "services";
import { TableType } from "core/tables";
import { IMetaProps } from "services/TableService";

export class MainMapBoxController {
    public initAreas() {
        areaService.initAreas()
    }

    public initTable(tableType: TableType, meta: IMetaProps | undefined) {
        tableService.initTable(tableType, meta);
    }
}

export const mainMapBoxController = new MainMapBoxController();
