import React, { useCallback, useContext, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { DataScroller } from "primereact/datascroller";
import { areaStore, modalStore } from "stores";
import { AccountInfoButton } from "../";
import { AreaNoteTemplate } from "./AreaNoteTemplate/AreaNoteTemplate";
import { DEVICE_TYPES, DeviceType } from "hooks/useDeviceType";
import { IDrawWithId } from "services/AreaService";
import { getRights } from "utils";
import RightContext from "contexts/AppRightsProvider";
import { ModalType } from "core/modals";
import { areaActionTypes } from "components/Modals/CreateModal/CreateModal";
import styles from './AreasSideBarLayout.module.scss'

interface SideBarLayoutProps {
    accountInfoClassName: string;
    deviceType: DeviceType;
}

export const AreasSideBarLayout = observer(({accountInfoClassName, deviceType}: SideBarLayoutProps) => {

    const {rights} = useContext(RightContext);
    const {areas} = areaStore;
    const [visible, setVisible] = useState<boolean>(false)
    const areaControllerRights = useMemo(() => getRights(rights, 'areaController'), [rights])

    const eventTemplate = (data: IDrawWithId) => {

        return <AreaNoteTemplate
            area={data}
        />
    }

    const handleOnAreaAddClick = useCallback(() => {
        if (areaControllerRights) modalStore.open({
            type: ModalType.AreaInfo,
            actionType: areaActionTypes.CREATE_AREA,
        })
        return
    }, [areaControllerRights])

    return <div className={styles.sideBarLayout}>
        <Sidebar
            position={"right"}
            visible={visible}
            dismissable={false}
            modal={false}
            onHide={() => setVisible(false)}>
            <AccountInfoButton
                className={accountInfoClassName}
                setSideBarVisible={deviceType === DEVICE_TYPES.DESKTOP ? undefined : setVisible}
            />
            <div className={styles.dataScrollerContainer}>
                <DataScroller value={areas} itemTemplate={eventTemplate} rows={areas.length} buffer={0.4}/>
            </div>
            {areaControllerRights && <Button
              icon="pi pi-plus"
              className="p-button-rounded p-button-success"
              onClick={handleOnAreaAddClick}
            />}
        </Sidebar>
        <Button icon="pi pi-map" onClick={() => setVisible(true)}/>
    </div>
})
