import { IUnitType } from "../core/types";
import {
    AirAssaultBrigade,
    AirAssaultDivision,
    AirAssaultRegiment, UaAntiAircraftMissileRegiment,
    Army, AzovBrigade, Donbas, DonbasBatallion, ForeignLegion, Freikorps,
    InfantryBattalion,
    LightInfantryBrigade,
    MarineBrigade, MarineRegiment,
    Motorized,
    MotorizedDivision,
    MotorizedRegiment, TankBattalion,
    TankDivision,
    UaAirAssaultBattalion,
    UaAirAssaultBrigade,
    UaAirborneBattalion,
    UaAirMobileBrigade,
    UaArtilleryBrigade,
    UaArtilleryRegiment,
    UaAssaultBrigade, UaAviationBrigade,
    UaInfantryBattalion,
    UaInfantryBrigade,
    UaInfantryRegiment,
    UaIntelligenceBattalion, UaIntelligenceBattalions,
    UaJagerBrigade, UaMarineBattalion,
    UaMarineBrigade,
    UaMechanizedBattalion,
    UaMechanizedBrigade,
    UaMixedBattalion,
    UaMixedBrigade,
    UaMixedRegiment,
    UaMotorizedBrigade, UaNavalSpecialForces,
    UaOperationalMotorizedBrigade, UaRapidResponseBrigade,
    UaRocketArtilleryBrigade,
    UaSpecialForces,
    UaSpecialForcesBattalion, UASpecialForcesRegiment,
    UaSpecialPoliceForce,
    UaSpecialPoliceForceBattalion,
    UaSpecialPoliceForceRegiment,
    UaTankBattalion,
    UaTankBrigade, VolunteerArmy, UaAntiAircraftMissileBrigade, TankRegiment, ArtilleryBrigade
} from "../assets/icons/icons";
import { Akhmat, Wagner } from "../components/Marker/RenderMarker/RenderMarker";

export const UNIT_TYPES: IUnitType[] = [
    {
        type: 'airassault',
        value: 'airassault',
        name: 'Air Assault Brigade',
        component: AirAssaultBrigade
    }, {
        type: 'airassaultRegiment',
        value: 'airassaultRegiment',
        name: 'Air Assault Regiment',
        component: AirAssaultRegiment,
    }, {
        type: 'marineRegiment',
        value: 'marineRegiment',
        name: 'Marine Regiment',
        component: MarineRegiment,
    }, {
        type: 'marineBrigade',
        value: 'marineBrigade',
        name: 'Marine Brigade',
        component: MarineBrigade,
    }, {
        type: 'infantryBattalion',
        value: 'infantryBattalion',
        name: 'Infantry Battalion',
        component: InfantryBattalion,
    }, {
        type: 'motorizedRegiment',
        value: 'motorizedRegiment',
        name: 'Motorized Regiment',
        component: MotorizedRegiment,
    }, {
        type: 'motorized',
        value: 'motorized',
        name: 'Motorized Brigade',
        component: Motorized,
    }, {
        type: 'motorizedDivision',
        value: 'motorizedDivision',
        name: 'Motorized Division',
        component: MotorizedDivision,
    }, {
        type: 'TankRegiment',
        value: 'TankRegiment',
        name: 'Tank Regiment',
        component: TankRegiment,
    }, {
        type: 'tankBattalion',
        value: 'tankBattalion',
        name: 'Tank Battalion',
        component: TankBattalion,
    }, {
        type: 'tankDivision',
        value: 'tankDivision',
        name: 'Tank Division',
        component: TankDivision,
    }, {
        type: 'airAssaultDivision',
        value: 'airAssaultDivision',
        name: 'Air Assault Division',
        component: AirAssaultDivision,
    }, {
        type: 'artilleryBrigade',
        value: 'artilleryBrigade',
        name: 'Artillery Brigade',
        component: ArtilleryBrigade,
    }, {
        type: 'army',
        value: 'army',
        name: 'Army',
        component: Army,
    },
    {
        type: 'uaMechanizedBrigade',
        value: 'uaMechanizedBrigade',
        name: 'UA Mechanized Brigade',
        component: UaMechanizedBrigade,
        hasImage: true,
    },
    {
        type: 'uaMechanizedBattalion',
        value: 'uaMechanizedBattalion',
        name: 'UA Mechanized Battalion',
        component: UaMechanizedBattalion,
        hasImage:true,
    }, {
        type: 'uaAirAssaultBrigade',
        value: 'uaAirAssaultBrigade',
        name: 'UA Air Assault Brigade',
        component: UaAirAssaultBrigade,
        hasImage: true,
    }, {
        type: 'uaAirAssaultBattalion',
        value: 'uaAirAssaultBattalion',
        name: 'UA Air Assault Battalion',
        component: UaAirAssaultBattalion,
        hasImage: true
    }, {
        type: 'uaAirMobileBrigade',
        value: 'uaAirMobileBrigade',
        name: 'UA Air Mobile Brigade',
        component: UaAirMobileBrigade,
        hasImage: true,
    }, {
        type: 'uaAirborneBattalion',
        value: 'uaAirborneBattalion',
        name: 'UA Airborne Battalion',
        component: UaAirborneBattalion,
        hasImage: true
    }, {
        type: 'uaAssaultBrigade',
        value: 'uaAssaultBrigade',
        name: 'UA Assault Brigade',
        component: UaAssaultBrigade,
        hasImage: true,
    }, {
        type: 'uaMotorizedBrigade',
        value: 'uaMotorizedBrigade',
        name: 'UA Motorized Brigade',
        component: UaMotorizedBrigade,
        hasImage:true
    }, {
        type: 'uaSpecialForces',
        value: 'uaSpecialForces',
        name: 'UA Special Forces',
        component: UaSpecialForces,
        hasImage: true,
    }, {
        type: 'uaSpecialForcesRegiment',
        value: 'uaSpecialForcesRegiment',
        name: 'UA Special Forces Regiment',
        component: UASpecialForcesRegiment,
        hasImage: true,
    }, {
        type: 'uaSpecialForcesBattalion',
        value: 'uaSpecialForcesBattalion',
        name: 'UA Special Forces Battalion',
        component: UaSpecialForcesBattalion,
        hasImage: true,
    }, {
        type: 'uaInfantryRegiment',
        value: 'uaInfantryRegiment',
        name: 'UA Infantry Regiment',
        component: UaInfantryRegiment,
        hasImage: true,
    }, {
        type: 'uaInfantryBattalion',
        value: 'uaInfantryBattalion',
        name: 'UA Infantry Battalion',
        component: UaInfantryBattalion,
        hasImage: true
    }, {
        type: 'uaInfantryBrigade',
        value: 'uaInfantryBrigade',
        name: 'UA Infantry Brigade',
        component: UaInfantryBrigade,
        hasImage:true,
    }, {
        type: 'lightInfantryBrigade',
        value: 'lightInfantryBrigade',
        name: 'Light Infantry Brigade',
        component: LightInfantryBrigade,
        hasImage: true,
    }, {
        type: 'uaIntelligenceBattalion',
        value: 'uaIntelligenceBattalion',
        name: 'UA Intelligence Battalion',
        component: UaIntelligenceBattalion,
        hasImage:true,
    }, {
        type: 'uaIntelligenceBattalions',
        value: 'uaIntelligenceBattalions',
        name: 'UA Intelligence Battalions',
        component: UaIntelligenceBattalions,
        hasImage:true,
    }, {
        type: 'uaMarineBattalion',
        value: 'uaMarineBattalion',
        name: 'UA Marine Battalion',
        component: UaMarineBattalion,
        hasImage: true,
    }, {
        type: 'uaMarineBrigade',
        value: 'uaMarineBrigade',
        name: 'UA Marine Brigade',
        component: UaMarineBrigade,
        hasImage: true,
    }, {
        type: 'uaNavalSpecialForces',
        value: 'uaNavalSpecialForces',
        name: 'UA Naval Special Forces',
        component: UaNavalSpecialForces,
        hasImage: true,
    }, {
        type: 'uaJagerBrigade',
        value: 'uaJagerBrigade',
        name: 'UA Jager Brigade',
        component: UaJagerBrigade,
        hasImage: true,
    }, {
        type: 'uaMixedRegiment',
        value: 'uaMixedRegiment',
        name: 'UA Mixed Regiment',
        component: UaMixedRegiment,
        hasImage:true,
    }, {
        type: 'uaMixedBattalion',
        value: 'uaMixedBattalion',
        name: 'UA Mixed Battalion',
        component: UaMixedBattalion,
        hasImage: true,
    }, {
        type: 'uaOperationalMotorizedBrigade',
        value: 'uaOperationalMotorizedBrigade',
        name: 'UA Operational Motorized Brigade',
        component: UaOperationalMotorizedBrigade,
        hasImage: true,
    }, {
        type: 'uaRapidResponseBrigade',
        value: 'uaRapidResponseBrigade',
        name: 'UA Rapid Response Brigade',
        component: UaRapidResponseBrigade,
        hasImage: true,
    }, {
        type: 'uaMixedBrigade',
        value: 'uaMixedBrigade',
        name: 'UA Mixed Brigade',
        component: UaMixedBrigade,
        hasImage: true,
    }, {
        type: 'uaTankBrigade',
        value: 'uaTankBrigade',
        name: 'UA Tank Brigade',
        component: UaTankBrigade,
        hasImage: true,
    }, {
        type: 'uaArtilleryRegiment',
        value: 'uaArtilleryRegiment',
        name: 'UA Artillery Regiment',
        component: UaArtilleryRegiment,
    }, {
        type: 'uaArtilleryBrigade',
        value: 'uaArtilleryBrigade',
        name: 'UA Artillery Brigade',
        component: UaArtilleryBrigade,
        hasImage: true,
    }, {
        type: 'uaRocketArtilleryBrigade',
        value: 'uaRocketArtilleryBrigade',
        name: 'UA Rocket Artillery Brigade',
        component: UaRocketArtilleryBrigade,
        hasImage: true,
    }, {
        type: 'uaTankBattalion',
        value: 'uaTankBattalion',
        name: 'UA Tank Battalion',
        component: UaTankBattalion,
        hasImage: true,
    }, {
        type: 'uaSpecialPoliceForce',
        value: 'uaSpecialPoliceForce',
        name: 'UA Special Police Force',
        component: UaSpecialPoliceForce,
        hasImage: true,
    }, {
        type: 'uaSpecialPoliceForceBattalion',
        value: 'uaSpecialPoliceForceBattalion',
        name: 'UA Special Police Force Battalion',
        component: UaSpecialPoliceForceBattalion,
        hasImage: true,
    }, {
        type: 'uaSpecialPoliceForceRegiment',
        value: 'uaSpecialPoliceForceRegiment',
        name: 'UA Special Police Force Regiment',
        component: UaSpecialPoliceForceRegiment,
        hasImage: true,
    }, {
        type: 'wagner',
        value: 'wagner',
        name: 'Wagner',
        component: Wagner,
    }, {
        type: 'akhmat',
        value: 'akhmat',
        name: 'Special Unit Akhmat',
        component: Akhmat,
    }, {
        type: 'uaVolunteerArmy',
        value: 'uaVolunteerArmy',
        name: 'UA Volunteer Army',
        component: VolunteerArmy,
        hasImage: true,
    }, {
        type: 'uaAviationBrigade',
        value: 'uaAviationBrigade',
        name: 'UA Aviation Brigade',
        component: UaAviationBrigade,
        hasImage: true,
    }, {
        type: 'uaAntiAircraftMissileRegiment',
        value: 'uaAntiAircraftMissileRegiment',
        name: 'UA Anti-Aircraft Missile Regiment',
        component: UaAntiAircraftMissileRegiment,
        hasImage: true,
    }, {
        type: 'uaAntiAircraftMissileBrigade',
        value: 'uaAntiAircraftMissileBrigade',
        name: 'UA Anti-Aircraft Missile Brigade',
        component: UaAntiAircraftMissileBrigade,
        hasImage: true,
    }, {
        type: 'azov',
        value: 'azov',
        name: 'AZOV',
        component: AzovBrigade,
    }, {
        type: 'donbas',
        value: 'donbas',
        name: 'Donbas Batallion',
        component: DonbasBatallion,
    }, {
        type: 'freikorps',
        value: 'freikorps',
        name: 'Freikorps',
        component: Freikorps,
    }, {
        type: 'foreignLegion',
        value: 'foreignLegion',
        name: 'Foreign Legion',
        component: ForeignLegion,
    }, {
        type: 'donbasBatallion',
        value: 'donbasBatallion',
        name: 'Donbas',
        component: Donbas,
    },

]
