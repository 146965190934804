const EVENTS_ICON_PATH = '/dynamic/map/icons/events/';
export const SIZES = {
    SIZE_128: '@128x128.png',
    SIZE_64: '@64x64.png',
    SIZE_32: '@32x32.png',
};

export const getImageUrl = (countryCode: string, icon: string, size: string = SIZES.SIZE_128) => {
    const url = `${EVENTS_ICON_PATH}${countryCode}-${icon}${size}`;
    const type = `${countryCode}-${icon}${size}`;
    return {
        url,
        type
    };
}
