import React, { ReactNode } from "react";
import styles from './ModeButton.module.scss';
import cn from "clsx";

export interface IModeButton {
    icon?: string;
    isModeEnabled: boolean;
    disabled: boolean;
    getText?: () => ReactNode;
    onClick: () => void;
}

export const ModeButton = React.memo(({icon, isModeEnabled, disabled = false, getText, onClick}: IModeButton) => {

    const modeButtonClasses = cn(
        isModeEnabled ? [styles.button, styles.active] : [styles.button]);
    const textClasses = cn(
        isModeEnabled ? [styles.text, styles.text_active] : [styles.text]);

    return (
        <button className={modeButtonClasses} onClick={onClick} disabled={disabled}>
            {icon && <img src={icon} alt={icon}/>}
            {getText && <div className={textClasses}>{getText()}</div>}
        </button>
    )
})
