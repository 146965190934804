import React, { Dispatch, SetStateAction, useCallback } from "react";
import { colorsService } from 'services';
import styles from './ColorButton.module.scss';

interface ColorButtonProps {
    color: string;
    setIsChangeColorOpen: Dispatch<SetStateAction<boolean>>;
    onClick?: () => void;
    resetDrawState?: () => void;
}

export const ColorButton = React.memo(({
                                           color,
                                           setIsChangeColorOpen,
                                           onClick = undefined,
                                           resetDrawState = undefined
                                       }: ColorButtonProps) => {

    const setDrawingsColor = useCallback(() => {
        colorsService.setDrawingColor(color);
        setIsChangeColorOpen(false)
        if (resetDrawState) resetDrawState()
    }, [color, setIsChangeColorOpen, resetDrawState])

    return <div
        className={styles.button}
        style={{backgroundColor: color}}
        onClick={onClick ? onClick : setDrawingsColor}
    />
})
