import { configure, makeObservable, runInAction } from 'mobx';
import { action, observable } from 'mobx';
import { ModalProps } from 'core/modals';

configure({
  useProxies: 'never',
});

export class ModalStore {
  public props: ModalProps | null = null;

  constructor() {
    makeObservable(this, {
      props: observable,
      open: action,
      close: action,
      reset: action,
    });

    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  open(props: ModalProps) {
    runInAction(() => this.props = props);
  }

  close() {
    this.reset();
  }

  reset() {
    this.props = null;
  }
}

export const modalStore = new ModalStore();
