import { TableType } from "core/tables";
import { ModalType } from "../core/modals";
import { getMarkerTypeInfo } from "./getMarkerTypeInfo";
import { getCountryInfo } from "./getCountryInfo";
import { ICountry, IMarker, IMarkerType } from "../core/types";

export type ITableHeaders = {
    name: string;
    entityField?: string | undefined
    entityFunction?: (entity: IMarker) => IMarkerType | ICountry | undefined,
}

export interface IStructure {
    tableType: TableType;
    tableTitle: string;
    searchTitle: string;
    removeButtonTitle: string;
    removeManyButtonTitle: string;
    headers: ITableHeaders[];
    addButton: boolean;
    addButtonTitle?: string;
    editButton?: boolean;
    editButtonTitle?: string;
    pagination: {
        paginator: boolean,
        rows: number,
        rowsPerPageOptions: number[]
    }
}

const TABLE_STRUCTURES: IStructure[] = [
    {
        tableType: TableType.CountriesTable,
        tableTitle: 'Countries',
        searchTitle: 'Search Country By Title',
        removeButtonTitle: 'Remove Country',
        removeManyButtonTitle: 'Remove Countries',
        headers: [
            {
                name: 'checkBox',
                entityField: 'checkBox',
            }, {
                name: 'Title',
                entityField: 'title',
            }, {
                name: 'Short Title',
                entityField: 'titleNative',
            }, {
                name: 'Description',
                entityField: 'desc',
            }, {
                name: 'International Code',
                entityField: 'code',
            }
        ],
        addButton: true,
        addButtonTitle: 'Add Country',
        pagination: {
            paginator: true,
            rows: 5,
            rowsPerPageOptions: [5, 10, 25]
        }
    }, {
        tableType: TableType.MarkersTable,
        tableTitle: 'Markers',
        searchTitle: 'Search Marker By Title',
        removeButtonTitle: 'Remove Marker',
        removeManyButtonTitle: 'Remove Markers',
        headers: [
            {
                name: 'checkBox',
                entityField: 'checkBox',
            }, {
                name: 'Id',
                entityField: 'id',
            }, {
                name: 'Title',
                entityField: 'title',
            }, {
                name: 'Description',
                entityField: 'desc',
            }, {
                name: 'Icon',
                entityField: 'icon',
            }, {
                name: 'Marker Type',
                entityField: 'markerType',
                entityFunction: (entity) => getMarkerTypeInfo(entity.type),
            }, {
                name: 'Country',
                entityField: 'country',
                entityFunction: (entity) => getCountryInfo(entity.country),
            }
        ],
        addButton: false,
        editButton: true,
        editButtonTitle: 'Edit Marker',
        pagination: {
            paginator: true,
            rows: 5,
            rowsPerPageOptions: [5, 10, 25]
        }
    }, {
        tableType: TableType.MarkerTypesTable,
        tableTitle: 'Marker Types',
        searchTitle: 'Search Marker Type By Title',
        removeButtonTitle: 'Remove Marker Type',
        removeManyButtonTitle: 'Remove Marker Types',
        headers: [
            {
                name: 'checkBox',
                entityField: 'checkBox',
            }, {
                name: 'Id',
                entityField: 'id',
            }, {
                name: 'Title',
                entityField: 'title',
            }, {
                name: 'Description',
                entityField: 'desc',
            }, {
                name: 'Icon',
                entityField: 'icon',
            }
        ],
        addButton: true,
        addButtonTitle: 'Add Marker Type',
        pagination: {
            paginator: true,
            rows: 5,
            rowsPerPageOptions: [5, 10, 25]
        }
    },

]

export const getTablesStructure = (tableType: TableType): IStructure => {
    return TABLE_STRUCTURES.find(table => table.tableType === tableType) as IStructure
}

export const getModalTypeByTableType = (tableType: TableType) => {
    if (tableType === TableType.CountriesTable) return ModalType.CreateCountry
    if (tableType === TableType.MarkerTypesTable) return ModalType.CreateMarkerTypes
    if (tableType === TableType.MarkersTable) return ModalType.CreateMarker
    return undefined
}

export const getTableTypeByModalType = (modalType: ModalType) => {
    if (modalType === ModalType.CreateCountry) return TableType.CountriesTable
    if (modalType === ModalType.CreateMarkerTypes) return TableType.MarkerTypesTable
    return TableType.MarkersTable
}
