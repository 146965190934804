import { LoginResponse } from 'interfaces/Response';
import { authStore } from 'stores';
import { apiService, localStorageService, meService } from './';
import { ApiConfig } from "./ApiService";

export interface UserCredentials {
    email: string;
    password?: string;
    lang?: string;
    roles?: string[],
    code?: string;
}

export class AuthService {
    public async logIn(payload: UserCredentials) {
        return await apiService.axiosPostRequest(ApiConfig.AUTH_SIGN_IN, payload)
    }

    public async logout() {
        const {localStorageAccessToken} = localStorageService;
        return await apiService.axiosGetRequest(ApiConfig.AUTH_LOG_OUT, localStorageAccessToken).then(() => {
            this.setInvalidState()
            meService.setMeToStore(null)
            localStorageService.setLocalStorageAccessToken('');
            localStorageService.setLocalStorageRefreshToken('');
        }).catch(() => {
            this.setInvalidState()
            meService.setMeToStore(null)
            localStorageService.setLocalStorageAccessToken('');
            localStorageService.setLocalStorageRefreshToken('');
        })
    }

    public async signUp(payload: UserCredentials) {
        return await apiService.axiosPostRequest(ApiConfig.ACCOUNT_REGISTER, payload)
    }

    public async activateCode(payload: UserCredentials) {
        return await apiService.axiosPostRequest(ApiConfig.ACCOUNT_ACTIVATE, payload)
    }

    public async refreshAuthState() {
        if (localStorageService.getLocalStorageAccessToken()) {
            authStore.setLoadingState(true)
            return await this.refreshToken().then(res => {
                this.setAuthState(res.data);
                meService.getMeRequest(res.data.accessToken)
                authStore.setLoadingState(false)
            }).catch(() => {
                this.setInvalidState()
                authStore.setLoadingState(false)
            })
        }
    }

    public async refreshToken() {
        const {localStorageRefreshToken} = localStorageService;
        return apiService.axiosGetRequest(ApiConfig.TOKEN_REFRESH, localStorageRefreshToken);
        // return await axios.get<LoginResponse>(ApiConfig.TOKEN_REFRESH, AuthService.getRequestOptions(refreshToken) );
    }

    public setAuthState(res: LoginResponse) {
        if (!res) return authStore.setAuthState(undefined, false);
        authStore.setAuthState(res.accessToken, true);
        localStorageService.setLocalStorageAccessToken(res.accessToken);
        localStorageService.setLocalStorageRefreshToken(res.refreshToken)
    }

    public setInvalidState() {
        authStore.setAuthState(undefined, false);
        localStorageService.setLocalStorageAccessToken('');
        localStorageService.setLocalStorageRefreshToken('');
    }

    //
    // private static getRequestOptions(token: string): AxiosRequestConfig {
    //   return {
    //     method: "GET",
    //     headers: {
    //       Authorization: `Bearer ${token}`
    //     },
    //   };
    // }
}

export const authService = new AuthService();
