import React, { createContext, ReactNode, useEffect } from 'react';
import { observer } from "mobx-react";
import { authStore } from "stores";
import { authService, localStorageService, meService } from "services";
import { useLocation } from "react-router-dom";

export interface AuthContextValue {
    authState: AuthState;
}

export interface AuthState {
    accessToken?: string;
    isAuthenticated?: boolean;
}

const AuthContext = createContext<AuthContextValue | null>(null);

export const AppAuthProvider: React.FC<{
    children: ReactNode;
}> = observer(({children}) => {
    const location = useLocation();
    const {pathname} = location;
    const {authState} = authStore;

    useEffect(() => {
        if (pathname.includes('/auth/patreon/')) {
            const base64Tokens = pathname.replace('/auth/patreon/', '');
            if (base64Tokens) {
                const tokens = JSON.parse(atob(base64Tokens));
                authService.setAuthState(tokens)
                meService.getMeRequest(tokens.accessToken)
            }
        } else if (pathname.includes('/login')) {
            const accessToken = localStorageService.getLocalStorageAccessToken()
            const refreshToken = localStorageService.getLocalStorageRefreshToken()
            if (accessToken && refreshToken) {
                authService.setAuthState({
                    accessToken,
                    refreshToken
                });
                meService.getMeRequest(accessToken)
            }
        }

    }, [pathname])

    return (
        <AuthContext.Provider
            value={{
                authState: authState,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
})

export default AuthContext;
