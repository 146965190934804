import { areaService, IDrawWithId } from "services/AreaService";
import { Feature } from "geojson";
import { drawService } from "services/DrawService";

export class AddFeatureToAreaModalController {

    public async updateAreaAPIRequest(area: IDrawWithId, features: Feature[]) {
        const featureCollection = area.geoJson.features.concat(features)
        const _features = featureCollection.map(feature => {
            return {
                ...feature, properties: {
                    ...feature.properties,
                    isRemovable: true,
                    isCollection: true,
                }
            }
        })
        return await areaService.updateAreaAPIRequest(area, _features);
    }

    public updateAreaInStore(area: IDrawWithId) {
        areaService.updateAreaInStore(area);
    }

    public removeFeatureFromDrawData(id: string) {
        drawService.removeFeatureFromDrawData(id);
    }

    public removeAreaToSave(id: string) {
        drawService.removeAreaToSave(id);
    }
}

export const addFeatureToAreaModalController = new AddFeatureToAreaModalController();
