import { TableType } from "core/tables";
import { ICountryPayload, IMarkerTypePayload, IMeta } from "core/types";
import { tableStore, notificationStore, markersStore } from "stores";
import { CountryResponse, FilesResponse, MarkersResponse, MarkerTypeResponse } from "interfaces/Response";
import { apiService, authService, notificationService } from "./";
import { ApiConfig } from "./ApiService";
import { getApiUrlByTableType } from "utils";

export interface IMetaProps {
    page: number,
    take: number,
    search?: string,
}

export class TableService {

    public async initTable(tableType: TableType, meta: IMetaProps | undefined) {
        return await apiService.axiosGetRequest(getApiUrlByTableType(tableType), undefined, {
            page: meta?.page,
            take: meta?.take,
            search: meta?.search,
        }).then((res) => {
            this.setDataToTable(tableType, res.data.data)
            this.setMetaToStore(res.data.meta)
        }).catch((e) => {
            if (e.response.status === 401) authService.refreshAuthState()
            if (e.response.status === 403) authService.setInvalidState()
        });
    }

    public async createTableRow(tableType: TableType, payload: ICountryPayload | IMarkerTypePayload) {
        const {meta} = tableStore
        const params = {page: meta.page, take: meta.take}
        return await apiService.axiosPostRequest(getApiUrlByTableType(tableType), payload).then((res) => {
            notificationStore.push(notificationService.create({
                data: res.data,
                dataType: tableType,
                type: 'success',
                actionType: 'create'
            }))
            this.initTable(tableType, params)
        }).catch((e) => {
            if (e.response.status === 401) authService.refreshAuthState()
            if (e.response.status === 403) authService.setInvalidState()
        })
    }

    public async removeTableRow(tableType: TableType, ids: number[] | string[], onlyEntity = true) {
        const {meta} = tableStore
        const params = {page: meta.page, take: meta.take}

        if (ids && ids.length > 1) {
            if (tableType === TableType.CountriesTable) return await apiService.axiosDeleteRequest(ApiConfig.deleteCountries(), ids).then(() => {
                notificationStore.push(notificationService.create({
                    dataType: tableType,
                    type: 'success',
                    actionType: 'delete',
                }))
                this.initTable(tableType, params)
            }).catch((e) => {
                if (e.response.status === 401) authService.refreshAuthState()
                if (e.response.status === 403) authService.setInvalidState()
            });

            if (tableType === TableType.MarkerTypesTable) return await apiService.axiosDeleteRequest(ApiConfig.deleteMarkerTypes(), ids).then(() => {
                notificationStore.push(notificationService.create({
                    dataType: tableType,
                    type: 'success',
                    actionType: 'delete',
                }))
                this.initTable(tableType, params)
            }).catch((e) => {
                if (e.response.status === 401) authService.refreshAuthState()
            });

            if (tableType === TableType.MarkersTable) return await apiService.axiosDeleteRequest(ApiConfig.deleteMarkers(), ids).then(() => {
                notificationStore.push(notificationService.create({
                    dataType: tableType,
                    type: 'success',
                    actionType: 'delete',
                }))
                markersStore.removeMarkerFromStore(ids);
                this.initTable(tableType, params)
            }).catch((e) => {
                if (e.response.status === 401) authService.refreshAuthState()
                if (e.response.status === 403) authService.setInvalidState()
            });

        }

        if (ids && ids.length === 1) {
            if (tableType === TableType.CountriesTable) return await apiService.axiosDeleteRequest(ApiConfig.deleteCountry(ids.join(''))).then(() => {
                notificationStore.push(notificationService.create({
                    dataType: TableType.CountriesTable,
                    type: 'success',
                    actionType: 'delete',
                }))
                this.initTable(tableType, params)
            }).catch((e) => {
                if (e.response.status === 401) authService.refreshAuthState()
                if (e.response.status === 403) authService.setInvalidState()
            });

            if (tableType === TableType.MarkerTypesTable) return await apiService.axiosDeleteRequest(ApiConfig.deleteMarkerType(ids.join(''))).then(() => {
                notificationStore.push(notificationService.create({
                    dataType: tableType,
                    type: 'success',
                    actionType: 'delete',
                }))
                this.initTable(tableType, params)
            }).catch((e) => {
                if (e.response.status === 401) authService.refreshAuthState()
                if (e.response.status === 403) authService.setInvalidState()
            });

            if (tableType === TableType.MarkersTable) return await apiService.axiosDeleteRequest(ApiConfig.deleteMarker(ids.join(''))).then(() => {
                notificationStore.push(notificationService.create({
                    dataType: tableType,
                    type: 'success',
                    actionType: 'delete',
                }))
                markersStore.removeMarkerFromStore(ids);
                this.initTable(tableType, params);
            }).catch((e) => {
                if (e.response.status === 401) authService.refreshAuthState()
                if (e.response.status === 403) authService.setInvalidState()
            });

        }
    }

    openTable(tableType: TableType) {
        tableStore.open({
            type: tableType,
        })
    }

    closeTable() {
        tableStore.close()
    }

    setDataToTable(tableType: TableType, data: CountryResponse[] | FilesResponse[] | MarkerTypeResponse[] | MarkersResponse[]) {
        tableStore.setDataToTable(tableType, data)
    }

    setMetaToStore(meta: IMeta) {
        tableStore.setMeta(meta)
    }
}

export const tableService = new TableService();
